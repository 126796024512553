/* eslint-disable camelcase */

import React from 'react'
import {
  BlueBarTitle,
  GoogleMap,
  OfficeMapWindow
} from 'molecules'

const OfficeMap = ({ practiceOffices, locationCoordinates, boundingBoxArray, ...rest }) => {
  return (
    <section id='office_map'>
      <BlueBarTitle title={'Surgical & Dental Offices'} />
      <GoogleMap
        zoom={11}
        defaultCenter={{ lat: locationCoordinates[0], lng: locationCoordinates[1] }}
        markers={practiceOffices}
        infoWindow={OfficeMapWindow}
        bounds={boundingBoxArray}
        {...rest}
      />

    </section>
  )
}

export default OfficeMap

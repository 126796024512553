/* eslint-disable camelcase */

import React from 'react'
import { compose } from 'recompose'
import { withRouter } from 'react-router-dom'
import { withFormatting } from 'helpers'

import {
  Row,
  Col,
  Button,
  FeaturedImage,
  Link
} from 'atoms'

const ProfileListItem = ({ profile, formatPhoneNumber, history }) => {
  const {
    // office_address_2,
    slug,
    // office_address_1,
    profile_image,
    office_city,
    // office_phone,
    office_state,
    // office_zip,
    practice_name,
    practice_areas_serviced,
    user_name
  } = profile

  return (
    <Row className='profile-card-row'>
      <Col sm='12'>
        <Link to={`/doctors/${slug}`}>
          <FeaturedImage size='md' src={profile_image} />
        </Link>
      </Col>
      <Col sm='12' className='profile-card'>
        <Link to={`/doctors/${slug}`}>
          <h3 className='user-name'>{user_name}</h3>
        </Link>
        <p>{practice_name}</p>
        {
          (practice_areas_serviced && practice_areas_serviced.trim() !== '') ? (
            <p>{practice_areas_serviced}</p>
          ) : (
            <p>{office_city}, {office_state}</p>
          )
        }
        <Button className='learn-more-btn' onClick={() => history.push(`/doctors/${slug}`)}>Learn More</Button>
      </Col>
    </Row>
  )
}

export default compose(
  withFormatting,
  withRouter
)(ProfileListItem)

import React from 'react'

const Avatar = ({ img }) => {
  return (
    // Only show avatar if img provided and is not default "missing image"
    img && !/missing-avatar\.png$/.test(img)
      ? <img src={img} alt='user avatar' className='avatar-img' />
      : <div className='avatar-container'>
        <i className='fas fa-user' />
      </div>
  )
}

export default Avatar

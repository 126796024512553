import Practices from './Practices'
import qs from 'qs'
import { withRouter } from 'react-router-dom'

import { compose, withHandlers, withPropsOnChange, withStateHandlers } from 'recompose'
import { api, notifier, storage } from 'lib'
import { withFormatting, withTracking } from 'helpers'
import { withMaybe } from '@bowtie/react-utils'

const emptyConditionFn = ({ practice }) => !practice || !practice.id

export default compose(
  withRouter,
  withTracking,
  withFormatting,
  withStateHandlers({
    practice: {},
    isModalOpen: false
  }, {
    setPractice: () => (payload) => ({ practice: payload }),
    toggleModal: ({ isModalOpen }) => () => ({ isModalOpen: !isModalOpen })
  }),
  withHandlers({
    getPractice: ({ setPracticesList, setPractice, getPracticeDentists, setIsModelLoading, match, queryParams }) => () => {
      const { modelId } = match.params
      // setIsModelLoading(true)
      api.get(`/directory/practices/${modelId}?${qs.stringify(queryParams)}`)
        .then(({ data, pages, meta }) => {
          setPractice(data)
          // setIsModelLoading(false)
        })
        .catch((resp) => {
          console.log('resp', resp.errors)
          storage.remove('selected_surgeon_group')
          notifier.bad(resp)
        })
    }
  }),
  withPropsOnChange(['match'], ({ getPractice }) => {
    getPractice()
  }),
  withPropsOnChange([ 'practice' ], ({ practice, onPageViewTracking }) => {
    if (practice && practice.name) {
      onPageViewTracking({
        category: 'Practice View',
        label: practice.name
      })
    }
  }),
  withMaybe(emptyConditionFn)
)(Practices)

/* eslint-disable camelcase */

import React from 'react'
import questionImg from 'images/question-icon.png'
import {
  Row,
  ExtLink
} from 'atoms'

const Questions = ({ profile }) => {
  return (
    <section className='questions-section logo-bg'>
      <div className='flex-column center title'>Considering implants, but have questions?</div>
      <Row className='col-sm-12 col-md-10 col-lg-8 row contained'>

        <div className='col-md-6 col-xl-4 faq-card-box'>
          <ExtLink href={`${process.env.REACT_APP_BASE_MARKETING_URL}dental-implants/implant-options/`}>
            <div className='faq-box'>
              <img alt='ASIRD faq' src={questionImg} className='faq-icon' />
              <h4>Options</h4>
              <p>What are my tooth replacement options?</p>
              <div className='learn-more'>Learn More</div>
            </div>
          </ExtLink>
        </div>

        <div className='col-md-6 col-xl-4 faq-card-box'>
          <ExtLink href={`${process.env.REACT_APP_BASE_MARKETING_URL}dental-implants/benefits/`}>
            <div className='faq-box'>
              <img alt='ASIRD faq' src={questionImg} className='faq-icon' />
              <h4>Benefits</h4>
              <p>What are the benefits of dental implants over other tooth replacement options?</p>
              <div className='learn-more'>Learn More</div>
            </div>
          </ExtLink>
        </div>

        <div className='col-md-6 col-xl-4 faq-card-box'>
          <ExtLink href={`${process.env.REACT_APP_BASE_MARKETING_URL}dental-implants/what-to-expect/`}>
            <div className='faq-box'>
              <img alt='ASIRD faq' src={questionImg} className='faq-icon' />
              <h4>Procedure</h4>
              <p>What should I expect from a dental implant procedure?</p>
              <div className='learn-more'>Learn More</div>
            </div>
          </ExtLink>
        </div>

        <div className='col-md-6 col-xl-4 faq-card-box'>
          <ExtLink href={`${process.env.REACT_APP_BASE_MARKETING_URL}about/why-asird/`}>
            <div className='faq-box'>
              <img alt='ASIRD faq' src={questionImg} className='faq-icon' />
              <h4>Advantages</h4>
              <p>What are the advantages of choosing an ASIRD team for my procedure?</p>
              <div className='learn-more'>Learn More</div>
            </div>
          </ExtLink>
        </div>

        <div className='col-md-6 col-xl-4 faq-card-box'>
          <ExtLink href={`${process.env.REACT_APP_BASE_MARKETING_URL}dental-implants/patient-stories/`}>
            <div className='faq-box'>
              <img alt='ASIRD faq' src={questionImg} className='faq-icon' />
              <h4>Patient Stories</h4>
              <p>View dental implant stories from real patients</p>
              <div className='learn-more'>Learn More</div>
            </div>
          </ExtLink>
        </div>

        <div className='col-md-6 col-xl-4 faq-card-box'>
          <ExtLink href={`${process.env.REACT_APP_BASE_MARKETING_URL}dental-implants/gallery/`}>
            <div className='faq-box'>
              <img alt='ASIRD faq' src={questionImg} className='faq-icon' />
              <h4>Before and After</h4>
              <p>View Before and After Dental Implant Gallery</p>
              <div className='learn-more'>Learn More</div>
            </div>
          </ExtLink>
        </div>

      </Row>
    </section>
  )
}

export default Questions

/* eslint-disable camelcase */

import React from 'react'
import YouTube from 'react-youtube'
import {
  Row,
  Col,
  Button,
  ExtLink
} from 'atoms'
import {
  Quote,
  Tabs
} from 'molecules'
import {
  PracticeTeamList,
  RequestConsultationForm
} from 'organisms'
import { TabPane } from 'reactstrap'

const PracticeInfoTabs = (props) => {
  const { practice, profile, formatPhoneNumber, match, toggleModal } = props
  console.log('profile', profile)
  return (
    <section>
      <Row className={'profile-info'}>
        <Col sm='12'>
          <Tabs
            tabRowClassName={'practice-info-tabs col-lg-7 col-md-9 offset-lg-5 offset-md-3'}
            className={'negMarTop blue-bar-section practice-tabs'}
            tabs={['surgical_team', 'about', 'practice_info', 'request_consultation']}
          >
            <TabPane tabId='surgical_team'>
              <Row>
                <Col sm='12' className='practice-about'>
                  <PracticeTeamList surgeonOnly {...props} />
                </Col>
              </Row>
            </TabPane>
            <TabPane tabId='about'>
              <Row>
                <Col md='6'>
                  {
                    practice['video']
                      ? <div className='dflex video row'>
                        <div className='embed-responsive embed-responsive-16by9'>
                          <YouTube
                            className='embed-responsive-item'
                            videoId={practice['video']}
                          />
                        </div>
                      </div>
                      : <Quote />
                  }
                </Col>
                <Col md='6'>
                  <p className='profile-tab-content'>{practice['overview']}</p>
                </Col>
              </Row>
            </TabPane>
            <TabPane tabId='practice_info'>
              <Row>
                <Col md='6'>
                  {
                    practice['video']
                      ? <div className='dflex video row'>
                        <div className='embed-responsive embed-responsive-16by9'>
                          <YouTube
                            className='embed-responsive-item'
                            videoId={practice['video']}
                          />
                        </div>
                      </div>
                      : <Quote />
                  }
                </Col>
                <Col md='6' className='practice-info'>
                  <h5 className='strong'>{practice['name']}</h5>
                  <br />

                  <p>
                    {practice['phone'] && <span><span className='strong'>Phone:</span> {formatPhoneNumber(practice['phone'])}</span>}
                    <br />
                    {practice['email'] && <span><span className='strong'>Email:</span> {practice['email']}</span>}
                    <br />
                    {practice['website'] && <span><span className='strong'>Website:</span> <ExtLink href={practice['website']}>{practice['website']}</ExtLink></span>}
                  </p>
                  <Button onClick={toggleModal} className='btn view-team-btn'>View Office Location(s)</Button>
                </Col>
              </Row>
            </TabPane>
            <TabPane tabId='request_consultation'>
              <Row>
                <Col md='6'>
                  {
                    practice['video']
                      ? <div className='dflex video row'>
                        <div className='embed-responsive embed-responsive-16by9'>
                          <YouTube
                            className='embed-responsive-item'
                            videoId={practice['video']}
                          />
                        </div>
                      </div>
                      : <Quote />
                  }
                </Col>
                <Col md='6' className='consultation-form in-tab'>
                  <RequestConsultationForm
                    modelName={match.params['modelName']}
                    modelId={match.params['modelId']}
                    {...props}
                  />
                </Col>
              </Row>
            </TabPane>
          </Tabs>
        </Col>
      </Row>
    </section>
  )
}

export default PracticeInfoTabs

/* eslint-disable camelcase */
/* eslint-disable camelcase */

import React from 'react'
import { titleize } from '@bowtie/utils'
import {
  FeaturedImage,
  Row,
  Col,
  Button,
  CertifiedBadge,
  ExtLink
} from 'atoms'
import {
  ProfessionalTitle,
  ModelHeader
} from 'molecules'

const DentistProfileInfo = ({ surgeonGroup, profile, formatFullTitle }) => {
  const {
    // id,
    // user_id,
    // education,
    // jumbo_image_url,
    // featured_image_1_url,
    // featured_image_2_url,
    // slug,
    // website_url,
    // experience,
    // interests,
    // practice_id,
    // surgeon_group_city,
    doctor_type = '',
    profile_image,
    bio,
    specialty = '',
    surgeon_group_name,
    dental_practice_name,
    practice_website
  } = profile

  return (
    <div>
      <div className='profile-info-section'>
        <Row>
          <Col sm='12'>
            <ModelHeader {...profile} surgeonGroup={surgeonGroup} />
          </Col>
          <Row className='profile-row-bottom'>
            <div className='vertical-text'>YOUR {titleize(doctor_type || '', '_').toUpperCase()}</div>
            <Col md='6'>
              <FeaturedImage className='lg' src={profile_image} />
            </Col>
            <Col md='6' className='flex-column justify-center'>
              <ProfessionalTitle
                name={formatFullTitle(profile)}
                specialty={specialty}
              />
              <div className='content'>
                <div className='doctor-profile-name-logo-wrapper'>
                  <p className='practice-name'>
                    {
                      practice_website
                        ? <ExtLink href={profile['practice_website']}>
                          {dental_practice_name || surgeon_group_name}
                        </ExtLink>
                        : <span>{dental_practice_name || surgeon_group_name}</span>
                    }
                  </p>
                </div>

                <p className='profile-tab-content'>{bio}</p>
              </div>
              <div className='content'>
                <Button className='contact-btn' href='#request_consultation_form'>Contact</Button>
              </div>
              <div className='content'>
                <CertifiedBadge profile={profile} />
              </div>
            </Col>
          </Row>
        </Row>
      </div>
    </div>
  )
}

export default DentistProfileInfo

/* eslint-disable camelcase */
import React from 'react'
import logo from './certified_badge_asird.png'
import { titleize } from '@bowtie/utils'
import moment from 'moment'

const CertifiedBadge = ({ profile }) => {
  const {
    // id,
    // user_id,
    // education,
    // jumbo_image_url,
    // featured_image_1_url,
    // featured_image_2_url,
    // slug,
    // website_url,
    // experience,
    // interests,
    // practice_id,
    // doctor_type = '',
    // profile_image,
    // medical_suffix,
    // bio,
    // specialty = '',
    // surgeon_group_name,
    // dental_practice_name,
    // membership_category,
    display_membership,
    created_at
    // name
  } = profile
  // TODO: make this historic date of record

  const member_since = moment(created_at)

  return (
    <div className='certified-badge-logo'>
      <img src={logo} className='logo' alt='logo' />
      <p className='strong member-level'>ASIRD {display_membership && titleize(display_membership, '_').toUpperCase()}</p>
      <p className='badge-status'>Member Since {member_since.format('MMMM YYYY')}</p>
    </div>
  )
}

export default CertifiedBadge

import React from 'react'

const FeaturedImage = ({ src, className, size = 'lg', ...rest }) => {
  return (
    <div className={`featured-img ${className || ''}`} {...rest} >
      <img alt='featured' className={`img ${size}`} src={src} />
    </div>
  )
}

export default FeaturedImage

/* eslint-disable camelcase */

import React from 'react'
import classNames from 'classnames'
import {
  BlueBarTitle,
  PracticeSurgeonListItem,
  PracticeDentistListItem
} from 'molecules'
import {
  TeamList,
  Carousel
} from 'organisms'

const PracticeTeamList = (props) => {
  const { practice, surgeonOnly, dentistOnly } = props
  return (
    <section className={classNames({ surgeonOnly, dentistOnly })}>
      {
        surgeonOnly &&
        <div className='profile-team-info-section upper'>
          <Carousel
            slidesToScroll={1}
            framePadding='0 50px'
            easing={'easePolyInOut'}
            cellAlign={'left'}
            speed={600}
            team={'surgeon'}
            component={PracticeSurgeonListItem}
            {...props}
          />
        </div>
      }

      {
        dentistOnly &&
        <div>
          <BlueBarTitle title={`Your ${practice['name']} Team`} className={'align-items-center team-name'} />
          <div className='profile-team-info-section lower'>
            <TeamList
              perPage={8}
              team={'dentist'}
              title='Dentists'
              component={PracticeDentistListItem}
              {...props}
            />
          </div>
        </div>
      }

    </section>
  )
}

export default PracticeTeamList

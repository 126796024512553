import React from 'react'
import ReactDOM from 'react-dom'
import './scss/index.scss'
import 'bootstrap/dist/css/bootstrap.min.css'
import 'core-js'
import Routes from './Routes/routes'
// import registerServiceWorker from './registerServiceWorker'
import { Helmet } from 'react-helmet'
import { ErrorBoundary } from 'ecosystems'

const RoutesWithErrorBoundary = () => (
  <ErrorBoundary>
    <Helmet>
      <meta charSet='utf-8' />

      <title>ASIRD | Find a team</title>

      <meta name='description' content={'ASIRD - Directory'} />
      <meta name='keywords' content={'dentist, dental implants, asird, surgeon, surgery'} />

      {/* <!-- Twitter Card data --> */}
      <meta name='twitter:card' value={'ASIRD - Directory'} />

      {/* <!-- Open Graph data --> */}
      <meta property='og:title' content={'ASIRD - Directory'} />
      <meta property='og:description' content={'ASIRD - Directory'} />
      <meta property='og:type' content='business.business' />
      <meta property='og:url' content={`${process.env.REACT_APP_BASE_DIRECTORY_URL}`} />
      <meta property='og:image' content={`${process.env.REACT_APP_BASE_DIRECTORY_URL}/img/logo.png`} />

      <link rel='canonical' href={`${process.env.REACT_APP_BASE_DIRECTORY_URL}`} />
    </Helmet>
    <Routes />
  </ErrorBoundary>
)

ReactDOM.render(<RoutesWithErrorBoundary />, document.getElementById('root'))
// registerServiceWorker()

/* eslint-disable camelcase */

import React from 'react'
import {
  Row,
  Col,
  Button,
  ExtLink
} from 'atoms'

const Quote = ({ quote = {}, readMoreBtn = false }) => {
  const {
    author = 'Wes',
    source = 'Dental Implant Patient',
    content = 'The experience was a lot better than what you would think…'
  } = quote

  return (
    <Row className='quote'>
      <Col sm='12'>
        <div className='quote-container'>
          <span className='quote-tick'>"</span>
          <div className='quote-text'>
            {content}
          </div>
          <div className='quote-citation d-flex align-items-center'>
            <span className='quote-author'>{author}</span> <span className='quote-source'>{source}</span>
          </div>
          {
            readMoreBtn &&
              <ExtLink href={`${process.env.REACT_APP_BASE_MARKETING_URL}dental-implants/patient-stories/`}>
                <Button className='contact-btn'>Read More</Button>
              </ExtLink>
          }
        </div>
      </Col>

    </Row>
  )
}

export default Quote

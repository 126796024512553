/* eslint-disable camelcase */

import React from 'react'
import {
  Row,
  Col,
  Button,
  PracticeLogo,
  ExtLink
} from 'atoms'
import {
  Quote,
  Tabs
} from 'molecules'
import { TabPane } from 'reactstrap'
import { withFormatting } from 'helpers'

const SpeakerInfoTabs = ({ profile, queryParams, offices = [], surgeonGroup, toggleModal, formatPhoneNumber, ...rest }) => {
  return (
    <section>
      <Row className={'profile-info'}>
        <Col md='6'>
          <Quote />
        </Col>
        <Col md='6'>

          <Tabs
            className={'negMarTop blue-bar-section'}
            tabs={['education', 'memberships/honors', 'seminars', 'contact']}
          >
            <TabPane tabId='education'>
              <Col sm='12' className='experience'>
                <p className='profile-tab-content'>{profile['education']}</p>
              </Col>
            </TabPane>
            <TabPane tabId='memberships/honors'>
              <Col sm='12' className='experience'>
                <p className='profile-tab-content'>{profile['membership_honors']}</p>
              </Col>

            </TabPane>
            <TabPane tabId='seminars'>
              <Col sm='12' className='experience'>
                <p className='profile-tab-content'>{profile['seminars']}</p>
              </Col>

            </TabPane>

            <TabPane tabId='contact'>
              <Row className='dentist-office-section'>
                <Col sm='12' className='dentist-office-title'>
                  <div className='doctor-profile-name-logo-wrapper'>
                    {
                      profile['practice_logo'] && <PracticeLogo src={profile['practice_logo']} size='sm' />
                    }
                  </div>
                  <h2 className='serving-area'>{profile['practice_overview']}</h2>
                  {
                    profile['practice_website'] && (
                      <ExtLink href={profile['practice_website']}>
                        <p className='dentist-website'>{profile['practice_website']}</p>
                      </ExtLink>
                    )
                  }
                </Col>
                {
                  offices.map((off, i) => {
                    let streetAddress = off['office_address_1']

                    if (off['office_address_2']) {
                      streetAddress += `, ${off['office_address_2']}`
                    }

                    return (
                      <Col sm='4' key={i} className='dentist-office-info'>
                        {
                          off['office_name'] && <div className='dentist-office-city'>{off['office_name']}</div>
                        }
                        {
                          streetAddress && <div className='dentist-office-detail'>{streetAddress}</div>
                        }
                        {
                          off['office_city'] && off['office_state'] && <div className='dentist-office-detail'>{off['office_city']}, {off['office_state']} {off['office_zip']}</div>
                        }
                        {
                          off['office_phone'] && <div className='dentist-office-detail'>Main: {formatPhoneNumber(off['office_phone'])}</div>
                        }
                        {
                          off['office_fax'] && <div className='dentist-office-detail'>Fax: {formatPhoneNumber(off['office_fax'])}</div>
                        }
                        {
                          off['office_hours'] && Object.keys(off['parsed_office_hours']).length > 0 &&
                          <div>
                            <div className='dentist-office-detail'>Hours:</div>
                            <div style={{ 'marginLeft': '10px' }}>
                              {
                                Object.keys(off['parsed_office_hours']).map((day, i) => <div key={i} className='dentist-office-detail'>{day}: {off['parsed_office_hours'][day]}</div>)
                              }
                            </div>
                          </div>
                        }
                      </Col>
                    )
                  })
                }
                <Col sm='12' className='office-map'>
                  <Button onClick={toggleModal} className='view-team-btn'>View Office Map</Button>
                </Col>
              </Row>
            </TabPane>
          </Tabs>
        </Col>
      </Row>
    </section>
  )
}

export default withFormatting(SpeakerInfoTabs)

import React from 'react'
import { Pagination as PaginationRS, PaginationItem, PaginationLink } from 'reactstrap'

const GeneralPagination = (props) => {
  const { children, nextPage, previousPage, goToPage, page, pageCount, startPage, endPage, size } = props

  const pageItems = []

  for (let i = startPage; i <= endPage; i++) {
    pageItems.push((
      <PaginationItem key={`paginate-${i}`} active={page === i}>
        <PaginationLink className={`page-nav page-nav-${i}`} onClick={(e) => goToPage(i)}>
          {i}
        </PaginationLink>
      </PaginationItem>
    ))
  }

  return (
    <PaginationRS size={size || 'sm'} className='pagination'>
      <PaginationItem disabled={page <= 1}>
        <PaginationLink disabled={page <= 1} onClick={() => goToPage(1)} className='prev-page'>
          first
        </PaginationLink>
      </PaginationItem>
      <PaginationItem disabled={page <= 1}>
        <PaginationLink disabled={page <= 1} previous onClick={previousPage} className='prev-page' />
      </PaginationItem>

      {pageItems}

      <PaginationItem disabled={page >= pageCount}>
        <PaginationLink disabled={page >= pageCount} next onClick={nextPage} className='next-page' />
      </PaginationItem>
      <PaginationItem disabled={page >= pageCount}>
        <PaginationLink disabled={page >= pageCount} onClick={() => goToPage(pageCount)} className='next-page'>
          last
        </PaginationLink>
      </PaginationItem>
      <PaginationItem>
        {children}
      </PaginationItem>
    </PaginationRS>
  )
}

export default GeneralPagination

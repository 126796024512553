import React from 'react'
import logo from './logo.svg'

const ReactLogo = () => {
  return (
    <img src={logo} className='react-logo' alt='logo' />
  )
}

export default ReactLogo

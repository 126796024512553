import { compose, lifecycle, withStateHandlers } from 'recompose'
import { api, notifier, storage } from 'lib'
import { withParamStorageParsing } from 'helpers'
import qs from 'qs'
import { withMaybe } from '@bowtie/react-utils'
import DoctorOfficeMap from './DoctorOfficeMap'

const nullConditionFn = ({ doctorOffices, locationCenter = [], boundingBox = [] }) => {
  return !(
    doctorOffices.length > 0 &&
    locationCenter.filter(i => typeof i === 'number').length === 2 &&
    boundingBox.filter(i => typeof i === 'number').length === 4
  )
}

export default compose(
  withParamStorageParsing,
  withStateHandlers({
    doctorOffices: [],
    locationCoordinates: storage.get('location_coordinates') || [],
    locationCenter: [],
    boundingBox: []
  }, {
    setdoctorOffices: () => (payload) => ({ doctorOffices: payload }),
    setLocationCoordinates: () => (payload) => ({ locationCoordinates: payload }),
    setLocationCenter: () => (payload) => ({ locationCenter: payload }),
    setBoundingBox: () => (payload) => ({ boundingBox: payload })
  }),

  lifecycle({
    componentDidMount () {
      const {
        profile,
        setdoctorOffices,
        setLocationCenter,
        setBoundingBox,
        findLocationCoordinates
      } = this.props

      const {
        customParams = {
          user_id: profile['slug'],
          per_page: 2000
        }
      } = this.props

      const locationCoordinates = findLocationCoordinates(profile['zip'] || profile['surgeon_group_zip'])

      // user_id=${profile['slug']}&per_page=2000&location=${locationCoordinates}

      api.get(`directory/offices?${qs.stringify(customParams)}&location=${locationCoordinates}`)
        .then(({ data, meta }) => {
          setdoctorOffices(data)
          setLocationCenter(meta && meta['location_center'] ? meta['location_center'] : [data[0]['latitude'], data[0]['longitude']])
          setBoundingBox(meta['bounding_box'])
        })
        .catch(notifier.bad.bind(notifier))
    }
  }),
  withMaybe(nullConditionFn)
)(DoctorOfficeMap)


import React from 'react'
import { Icon, TeamLoader } from 'atoms'
import Carousel from 'nuka-carousel'

const CarouselBT = ({ component: Component, teamList, slidesToShow, responsivePerPage, resizeHandler, isTeamLoading, ...rest }) => {
  return (
    isTeamLoading ? (<TeamLoader isLoading={isTeamLoading} />) : (
      <Carousel
        slidesToShow={responsivePerPage}
        onResize={resizeHandler}
        renderBottomCenterControls={() => null}
        renderCenterLeftControls={({ previousSlide, currentSlide, wrapAround, slideCount, ...rest }) => {
          // const disabled = (currentSlide === 0 && !wrapAround) || slideCount === 0;
          return (
            <Icon iconName='chevron-left' className='nav-arrow' size='md' onClick={previousSlide} />
          )
        }}
        renderCenterRightControls={({ nextSlide }) => {
          return (
            <Icon iconName='chevron-right' className='nav-arrow' size='md' onClick={nextSlide} />
          )
        }}
        {...rest}
      >
        {
          teamList.map((profile, i) => <Component key={i} profile={profile} />)
        }

        <TeamLoader isLoading={isTeamLoading} />
      </Carousel>
    )
  )
}

export default CarouselBT

/* eslint-disable camelcase */

import React from 'react'
import {
  Row,
  Col,
  Link
} from 'atoms'

const DoctorLinkedList = ({ doctors = [], title }) => {
  return (
    <section style={{ 'padding': '55px' }}>
      <h4 className='flex center pt-3 pb-3'>{title}</h4>
      <Row className='dflex col-sm-12 col-md-10 col-lg-8 col-xl-6 offset-xl-3 row contained offset-md-1 offset-lg-2 row' style={{ padding: '0 3%' }}>
        {
          doctors.map(doc => (
            <Col xs='12' sm='6' md='4'>
              <Link to={`/doctors/${doc['slug']}`}>{doc['first_name']} {doc['last_name']}</Link>
            </Col>
          ))
        }
      </Row>
    </section>
  )
}

export default DoctorLinkedList

// Containers should include all logic that enhances a component
// this includes any reduce methods, recompose, or middleware.

import { compose, withPropsOnChange } from 'recompose'
import { withEither } from '@bowtie/react-utils'
import scrollToElement from 'scroll-to-element'
import App from './App'
import { PageLoader } from 'atoms'
import { withParamStorageParsing } from 'helpers'

// conditional functions here:
const loadingConditionFn = (props) => props.isLoading
const newPageConditionFn = ({ location = {} }, { location: nextLocation = {} }) => location.pathname !== nextLocation.pathname

export const enhance = compose(
  withParamStorageParsing,
  withPropsOnChange(newPageConditionFn, () => {
    if (process.env.NODE_ENV !== 'test') {
      window.scrollTo(0, 0)
    }
  }),
  withPropsOnChange(['location'], ({ location = {} }) => {
    if (typeof window.gtag === 'function') {
      window.gtag('config', process.env.REACT_APP_GA_TRACKING, { 'page_path': location.pathname })
    }

    if (location['hash']) {
      scrollToElement(location['hash'], { offset: -60 })
    }
  }
  ),
  withEither(loadingConditionFn, PageLoader)
)

export default enhance(App)

/* eslint-disable camelcase */

import React from 'react'
import {
  Row,
  Col
} from 'atoms'
import {
  RequestConsultationForm
} from 'organisms'

const RequestConsultationSection = (props) => {
  return (
    <section className='request-consultation-section' id='request_consultation_form'>
      <Row className='d-flex align-items-center'>
        <Col md='6' lg='8' className='consultation-description text-center'>
          <h4 className='title'>The BEST Choice for Your Dental Implants</h4>
          <p className='description'>After a dental implant is placed, it takes six weeks to six months for the bone around the implant to create a secure, life-long bond. Once the bone graft is ready, a carefully crafted crown is secured to the implant that looks just like your surrounding teeth. Implants can even replace your dentures as a more comfortable, long-lasting solution that allows you to comfortably enjoy all types of food.</p>

        </Col>
        <Col md='6' lg='4'>
          <RequestConsultationForm {...props} />
        </Col>

      </Row>
    </section>
  )
}

export default RequestConsultationSection

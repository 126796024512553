import { compose, lifecycle, withStateHandlers } from 'recompose'
import { api, notifier, storage } from 'lib'
import { withFormatting } from 'helpers'
import { withMaybe } from '@bowtie/react-utils'
import OfficeMap from './OfficeMap'

const nullConditionFn = ({ practiceOffices }) => practiceOffices.length <= 0

export default compose(
  withFormatting,
  withStateHandlers({
    practiceOffices: [],
    locationCoordinates: storage.get('location_coordinates') || [],
    boundingBoxArray: []
  }, {
    setPracticeOffices: () => (payload) => ({ practiceOffices: payload }),
    setLocationCoordinates: () => (payload) => ({ locationCoordinates: payload }),
    setBoundingBoxArray: () => (payload) => ({ boundingBoxArray: payload })
  }),
  lifecycle({
    componentDidMount () {
      const { practice, setPracticeOffices, setBoundingBoxArray, setLocationCoordinates } = this.props
      const locationCoordinates = storage.get('location_coordinates')
      api.get(`directory/offices?surgeon_group_slug=${practice['slug']}&location=${locationCoordinates || `${practice['city']},${practice['zip']}`}&location_sort=${practice['city']},${practice['zip']}&per_page=2000`)
        .then(({ data, meta }) => {
          locationCoordinates
            ? setLocationCoordinates(locationCoordinates)
            : setLocationCoordinates([data[0]['latitude'], data[0]['longitude']])

          setPracticeOffices(data)
          setBoundingBoxArray(meta['bounding_box'])
        })
        .catch(notifier.bad.bind(notifier))
    }
  }),
  withMaybe(nullConditionFn)
)(OfficeMap)

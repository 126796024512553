/* eslint-disable camelcase */

import React from 'react'
import PropTypes from 'prop-types'
import {
  PracticeProfileInfo,
  PracticeInfoTabs,
  RequestConsultationSection,
  Questions,
  PracticeTeamList,
  OfficeMap,
  OfficeMapModal
} from 'organisms'
import { Helmet } from 'react-helmet'

/** ********** BASE COMPONENT **********/

const PracticeSingle = (props) => {
  const { practice, match, practiceOffices, ...rest } = props
  const { id } = practice
  const { params } = match

  const {
    name,
    overview,
    practice_logo
  } = practice

  const keywords = [
    name,
    'Dentist',
    'Dental Implants',
    'ASIRD',
    'Oral Surgeon',
    'Oral Surgery',
    'Maxofacial Surgery',
    'Maxofacial Surgeon',
    'Reconstructive Surgery',
    'Reconstructive Surgeon',
    'are dental implants safe',
    'benefits of dental implants',
    'about dental implants',
    'dental implant options',
    'implant technology',
    'dental implant technology',
    'dental implant maintenance',
    'dental implant testimonials',
    'dental implants before and after'
  ]

  const seoMeta = {
    keywords,
    type: 'business.business',
    title: `${name} | ASIRD.org`,
    image: practice_logo || `${process.env.REACT_APP_BASE_DIRECTORY_URL}/img/logo.png`,
    canonical: `${process.env.REACT_APP_BASE_DIRECTORY_URL}/${params['modelName']}/${params['modelId']}`,
    description: overview
  }

  return (
    <section id={`practice-${id}`}>
      <Helmet>
        <title>{seoMeta['title']}</title>
        <meta name='description' content={seoMeta['description']} />
        <meta name='keywords' content={seoMeta['keywords'].join(', ')} />

        {/* <!-- Twitter Card data --> */}
        <meta name='twitter:card' value={seoMeta['description']} />

        {/* <!-- Open Graph data --> */}
        <meta property='og:title' content={seoMeta['title']} />
        <meta property='og:description' content={seoMeta['description']} />
        <meta property='og:type' content={seoMeta['type']} />
        <meta property='og:url' content={seoMeta['canonical']} />
        <meta property='og:image' content={seoMeta['image']} />

        <link rel='canonical' href={seoMeta['canonical']} />
      </Helmet>

      {/* Header and practice logo */}
      <PracticeProfileInfo {...props} />

      {/* Tabbable Practice info */}
      <PracticeInfoTabs {...props} />

      {/* List of Dentists */}
      <PracticeTeamList dentistOnly {...props} />

      {/* Office Map */}
      <OfficeMap {...props} />

      <RequestConsultationSection
        modelName={params['modelName']}
        modelId={params['modelId']}
        {...props}
      />
      <Questions />

      <OfficeMapModal
        profile={practice}
        toggleModal={rest['toggleModal']}
        customParams={{ practice_id: practice['id'] }}
        {...rest}
      />
    </section>
  )
}

/** ********** EMPTY STATE **********/

/** ********** PROPTYPES **********/

PracticeSingle.propTypes = {
  practice: PropTypes.shape({
    name: PropTypes.string,
    short_name: PropTypes.string,
    phone: PropTypes.string,
    fax: PropTypes.string,
    website: PropTypes.string,
    overview: PropTypes.string,
    video: PropTypes.string,
    type: PropTypes.string,
    offices: PropTypes.array,
    users: PropTypes.array
  }).isRequired,
  formatPhoneNumber: PropTypes.func.isRequired
}

export default PracticeSingle

/* eslint-disable camelcase */

import React from 'react'
import {
  Row,
  Icon,
  TeamLoader
} from 'atoms'
import {
  Pagination
} from 'molecules'

const TeamList = ({ isTeamLoading, teamList, title, pages, component: Component, ...rest }) => {
  const { nextPage, previousPage, page } = rest
  const { pageCount = 1 } = pages
  return (
    <section className='team-list'>
      <h3 className='center title'>{title}</h3>

      {
        pageCount > 1 && page > 1 &&
        <Icon iconName='chevron-left' className='nav-arrow left' size='md' onClick={previousPage} />
      }

      <Row>
        { teamList.map((teamMember, i) => <Component key={i} profile={teamMember} />) }
      </Row>

      {
        pageCount > 1 && page < pageCount &&
        <Icon iconName='chevron-right' className='nav-arrow right' size='md' onClick={nextPage} />
      }

      <TeamLoader isLoading={isTeamLoading} />
      <Pagination {...pages} {...rest} />
    </section>
  )
}

export default TeamList

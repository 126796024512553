import React from 'react'
import { Form as FormRS } from 'reactstrap'

const FormBT = (props) => {
  const {
    childrenWithExtraProp,
    onSubmit,
    formData,
    setFormData,
    formOnChange,
    getErrors,
    handleChange,
    ...rest
  } = props

  return (
    <FormRS {...rest}>
      { childrenWithExtraProp }
    </FormRS>
  )
}

export default FormBT

import React from 'react'
import {
  ExtLink
} from 'atoms'
import logoFooter from 'images/logo-footer.png'

const Footer = (props) => {
  return (
    <footer id='footer' className='text-center'>

      <div className='text-center'>
        <div className='social-media'>

          <ExtLink href='https://www.youtube.com/channel/UCLU822w1l1OY9RxMf-ETxqQ'>
            <i className='fab fa-youtube' />
          </ExtLink>

          <ExtLink href='mailto:info@asird.org'>
            <i className='fas fa-envelope' />
          </ExtLink>
        </div>
      </div>

      <div className='upperfooter row'>
        <div className='col-md-2 order-2 order-md-1 d-flex flex-column' style={{ 'marginTop': '1em' }}>
          <ul className='footer-nav-list d-flex flex-column'>
            <li className='first'>
              <ExtLink href={`${process.env.REACT_APP_BASE_MARKETING_URL}about/`}>About ASIRD</ExtLink>
            </li>
            <li className=''>
              <ExtLink href={`${process.env.REACT_APP_BASE_MARKETING_URL}dental-implants/`}>Dental Implants</ExtLink>
            </li>
            <li className=''>
              <ExtLink href={`${process.env.REACT_APP_BASE_MARKETING_URL}dental-implants/gallery/`}>Gallery</ExtLink>
            </li>
            <li className=''>
              <ExtLink href={`${process.env.REACT_APP_BASE_MARKETING_URL}become-a-member/`}>Become a Member</ExtLink>
            </li>
            <li className=''>
              <a href='/'>Find a Dentist</a>
            </li>
          </ul>
        </div>

        <div className='col-md-8 order-1 order-md-2 d-flex flex-column align-items-center'>
          <ExtLink href={`${process.env.REACT_APP_BASE_MARKETING_URL}`}>
            <img className='footer-logo' alt='footer logo' src={logoFooter} />
          </ExtLink>
          <p className='small text-white'>AMERICAN SOCIETY OF IMPLANT & RECONSTRUCTIVE DENTISTRY</p>
        </div>
      </div>
      <div className='subfooter row'>
        <div className='col-sm-2 text-left'>
          <span className='small'>
            <ExtLink href={`${process.env.REACT_APP_BASE_MARKETING_URL}contact/`}>Contact Us</ExtLink>
          </span>
        </div>
        <div className='col-sm-8 text-center'>
          <span className='small'>
            <ExtLink href={`${process.env.REACT_APP_BASE_MARKETING_URL}privacy-policy/`}>Privacy Policy</ExtLink>
          </span>
          &nbsp;&nbsp;|&nbsp;&nbsp;
          <span className='small'>
            <ExtLink href={`${process.env.REACT_APP_BASE_MARKETING_URL}disclaimer/`}>Disclaimer</ExtLink>
          </span>
          &nbsp;&nbsp;|&nbsp;&nbsp;
          <span className='small'>
            <ExtLink href={`${process.env.REACT_APP_BASE_MARKETING_URL}hipaa-notice/`}>HIPAA Notice of Privacy Practices</ExtLink>
          </span>
          <br />
          <span className='small'>
            &copy; {(new Date()).getFullYear() || '2019'} ASIRD. All Rights Reserved.
          </span>
        </div>
      </div>
      <div id='google_translate_element' />
    </footer>
  )
}

export default Footer

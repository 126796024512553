/* eslint-disable camelcase */

import React from 'react'
import PropTypes from 'prop-types'
import { titleize } from '@bowtie/utils'
import {
  DentistProfileInfo,
  SurgicalTeam,
  DentistInfoTabs,
  SurgeonInfoTabs,
  SpeakerInfoTabs,
  RequestConsultationSection,
  Questions,
  OfficeMapModal
} from 'organisms'

import { DoctorLinkedList } from 'molecules'
import classnames from 'classnames'
import { Helmet } from 'react-helmet'

/** ********** BASE COMPONENT **********/

const DoctorSingle = (props) => {
  const { doctor, practiceSurgeons, practiceDentists, match, ...rest } = props
  const { offices = [], surgeonGroup = {} } = rest
  const { id, doctor_type } = doctor
  const { params } = match

  const {
    name,
    // bio,
    medical_suffix,
    surgeon_group_city,
    dental_practice_name,
    surgeon_group_name,
    speaker_practice_name,
    specialty = '',
    practice_logo
  } = doctor

  const keywords = [
    name,
    medical_suffix,
    'Dentist',
    'Dental Implants',
    'ASIRD',
    'Oral Surgeon',
    'Oral Surgery',
    'Maxofacial Surgery',
    'Maxofacial Surgeon',
    'Reconstructive Surgery',
    'Reconstructive Surgeon',
    'are dental implants safe',
    'benefits of dental implants',
    'about dental implants',
    'dental implant options',
    'implant technology',
    'dental implant technology',
    'dental implant maintenance',
    'dental implant testimonials',
    'dental implants before and after'
  ]

  if (medical_suffix) {
    keywords.push(medical_suffix)
  }

  if (specialty) {
    keywords.push(titleize(specialty, '_'))
  }

  if (surgeon_group_city) {
    keywords.push(surgeon_group_city)
  }

  if (surgeon_group_name) {
    keywords.push(surgeon_group_name)
  }

  if (dental_practice_name) {
    keywords.push(dental_practice_name)
  }

  if (speaker_practice_name) {
    keywords.push(speaker_practice_name)
  }

  offices.forEach(office => {
    office['office_city'] && keywords.push(`${office['office_city']}`)
    office['office_state'] && keywords.push(`${office['office_state']}`)
    office['office_zip'] && keywords.push(`${office['office_zip']}`)
  })

  const seoMeta = {
    keywords,
    type: 'profile',
    title: `${name} is an ASIRD Certified Implant and Reconstructive Dentist in ${surgeon_group_city}. ${dental_practice_name || surgeon_group_name} ${specialty ? titleize(specialty, '_') : ''} | ASIRD.org`,
    image: practice_logo || `${process.env.REACT_APP_BASE_DIRECTORY_URL}/img/logo.png`,
    canonical: `${process.env.REACT_APP_BASE_DIRECTORY_URL}/${params['modelName']}/${params['modelId']}`,
    description: `Receive a Dental Implant consultation from ${name} in ${surgeon_group_city}. ${name} is an ASIRD certified professional working with ${surgeon_group_name}.`
  }

  return (
    <section className={classnames({ speaker: doctor_type === 'speaker' })} id={`doctor-${id}`}>
      <Helmet>
        <title>{seoMeta['title']}</title>
        <meta name='description' content={seoMeta['description']} />
        <meta name='keywords' content={seoMeta['keywords'].join(', ')} />

        {/* <!-- Twitter Card data --> */}
        <meta name='twitter:card' value={seoMeta['description']} />

        {/* <!-- Open Graph data --> */}
        <meta property='og:title' content={seoMeta['title']} />
        <meta property='og:description' content={seoMeta['description']} />
        <meta property='og:type' content={seoMeta['type']} />
        <meta property='og:url' content={seoMeta['canonical']} />
        <meta property='og:image' content={seoMeta['image']} />

        <link rel='canonical' href={seoMeta['canonical']} />
      </Helmet>

      <DentistProfileInfo profile={doctor} {...rest} />

      {
        doctor_type === 'surgeon' &&
          <SurgeonInfoTabs
            profile={doctor}
            {...rest}
          />
      }

      {
        doctor_type === 'speaker' &&
          <SpeakerInfoTabs
            profile={doctor}
            {...rest}
          />
      }

      {
        doctor_type === 'dentist' &&
          <DentistInfoTabs
            profile={doctor}
            {...rest}
          />
      }

      {
        doctor_type === 'dentist' && practiceSurgeons.length > 0 &&
          <SurgicalTeam surgeons={practiceSurgeons} profile={doctor} />
      }

      <RequestConsultationSection
        modelName={params['modelName']}
        modelId={params['modelId']}
        offices={offices}
      />
      <Questions />
      {
        doctor_type === 'surgeon' && practiceDentists.length > 0 &&
          <DoctorLinkedList
            doctors={practiceDentists}
            title={surgeonGroup['areas_serviced'] ? `Top Implant and Reconstructive Dentists near ${surgeonGroup['areas_serviced']}`
              : `Top Implant and Reconstructive Dentists near ${surgeonGroup['city']}, ${surgeonGroup['state']}`
            }
          />
      }
      <OfficeMapModal
        profile={doctor}
        toggleModal={rest['toggleModal']}
        {...rest}
      />
    </section>
  )
}

/** ********** PROPTYPES **********/

DoctorSingle.propTypes = {
  doctor: PropTypes.shape({
    first_name: PropTypes.string,
    last_name: PropTypes.string,
    id: PropTypes.string,
    education: PropTypes.string,
    doctor_type: PropTypes.string,
    specialty: PropTypes.string,
    mobile_phone: PropTypes.string,
    office_phone: PropTypes.string,
    website_url: PropTypes.string,
    membership_category: PropTypes.string,
    bio: PropTypes.string,
    offices: PropTypes.array
  }).isRequired,
  formatPhoneNumber: PropTypes.func.isRequired
}

export default DoctorSingle

import React from 'react'
import classnames from 'classnames'
import { titleize } from '@bowtie/utils'
import { Row } from 'atoms'
import { TabContent, Nav, NavItem, NavLink } from 'reactstrap'

const Tabs = (props) => {
  const {
    tabs = [],
    dir = 'left',
    negMargin = false,
    setActiveTab,
    activeTab,
    handleClick,
    children,
    title,
    tabRowClassName,
    ...rest
  } = props

  return (
    <div {...rest}>
      <Nav tabs>
        <Row className={classnames({ negMargin, dirRight: dir === 'right' }, `profile-info-tabs ${tabRowClassName || ''}`)}>
          {
            tabs.map((tab, i) => (
              <NavItem key={i} className='doctor-single-tab'>
                <NavLink
                  onClick={() => handleClick(tab)}
                  className={classnames({ active: activeTab === tab }, 'doctor-single-tab')}
                >
                  {titleize(tab, '_')}
                </NavLink>
              </NavItem>
            ))
          }
        </Row>
      </Nav>
      <TabContent activeTab={activeTab} className='profile-extra-info-section'>
        { children }
      </TabContent>
    </div>
  )
}

export default Tabs

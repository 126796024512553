import { compose, withProps } from 'recompose'
import { withMaybe } from '@bowtie/react-utils'
import { withRouter } from 'react-router'
import Pagination from './Pagination'

const nullConditionalFn = ({ next, prev, pageCount }) => !(pageCount) || pageCount === 1

export default compose(
  withRouter,
  withMaybe(nullConditionalFn),
  withProps(({ page = 1, prev = {}, next = {}, pageCount = 1, maxItems = 3 }) => {
    let padded = Math.floor(maxItems / 2)
    let startPage = (page - padded) > 1 ? (page - padded) : 1
    let endPage = (startPage + maxItems - 1) >= pageCount ? pageCount : startPage + maxItems - 1

    if (maxItems % 2 !== 0) {
      padded++
    }

    let diff = pageCount - page
    let change = padded - diff - 1

    if (diff <= padded && change > 0) {
      if (startPage - change >= 1) {
        startPage -= change
      } else {
        startPage = 1
      }
    }

    const props = {
      page,
      prev,
      next,
      pageCount,
      maxItems,
      padded,
      startPage,
      endPage
    }

    return props
  })
)(Pagination)

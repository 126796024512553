/* eslint-disable no-undef */
/* global google */
import React from 'react'
import GoogleMap from './GoogleMap'
import { compose, withProps, withHandlers, withState } from 'recompose'
import { withGoogleMap, withScriptjs } from 'react-google-maps'

export default compose(
  withProps({
    googleMapURL: `https://maps.googleapis.com/maps/api/js?key=${process.env.REACT_APP_GOOGLE_API_KEY}&v=3.exp&libraries=geometry,drawing,places`,
    loadingElement: <div style={{ height: `100%` }} />,
    containerElement: <div style={{ height: `800px` }} />,
    mapElement: <div style={{ height: `100%` }} />
  }),
  withState('boundsInitialSet', 'setBoundsInitialSet', false),
  withState('activeMarker', 'setActiveMarker', null),
  withHandlers(() => {
    const refs = {
      map: undefined
    }

    return {
      onMapMounted: () => ref => {
        refs.map = ref
      },
      onTilesLoaded: ({ bounds, boundsInitialSet, setBoundsInitialSet }) => () => {
        if (bounds && !boundsInitialSet) {
          const sw = new google.maps.LatLng(bounds[0], bounds[1])
          const ne = new google.maps.LatLng(bounds[2], bounds[3])
          const newBounds = new google.maps.LatLngBounds(sw, ne)
          refs.map.panToBounds(newBounds)
          refs.map.fitBounds(newBounds)
          setBoundsInitialSet(true)
        }
      }
    }
  }),
  withScriptjs,
  withGoogleMap
)(GoogleMap)

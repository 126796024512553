/* eslint-disable camelcase */

import React from 'react'
import YouTube from 'react-youtube'
import {
  Row,
  Col,
  Button,
  PracticeLogo,
  ExtLink
} from 'atoms'
import {
  Quote,
  Tabs
} from 'molecules'
import { TabPane } from 'reactstrap'
import { withFormatting } from 'helpers'

const DentistInfoTabs = ({ profile, queryParams, offices = [], toggleModal, surgeonGroup, formatPhoneNumber, ...rest }) => {
  return (
    <section>
      <Row className={'profile-info'}>
        <Col md='5' lg='6'>
          {
            profile['practice_video']
              ? <div className='dflex video row'>
                <div className='embed-responsive embed-responsive-16by9'>
                  <YouTube
                    className='embed-responsive-item'
                    videoId={profile['practice_video']}
                  />
                </div>
              </div>
              : <Quote />
          }
        </Col>
        <Col md='7' lg='6'>
          <Tabs
            className={'negMarTop blue-bar-section'}
            tabs={['education', 'interests', 'practice_info']}
          >
            <TabPane tabId='education'>
              <p className='profile-tab-content'>{profile['education']}</p>
            </TabPane>
            <TabPane tabId='interests'>
              <p className='profile-tab-content'>{profile['interests']}</p>
            </TabPane>
            <TabPane tabId='practice_info'>
              <Row className='dentist-office-section'>
                <Col sm='12' className='dentist-office-title'>
                  <div className='doctor-profile-name-logo-wrapper'>
                    {
                      profile['practice_logo'] && <PracticeLogo src={profile['practice_logo']} size='sm' />
                    }
                  </div>
                  <h2 className='serving-area'>{profile['dental_practice_name']} Serves the Greater {offices && offices[0] && offices[0]['office_city'] ? offices[0]['office_city'] : profile['surgeon_group_city']} Area</h2>
                  {
                    profile['practice_website'] && (
                      <ExtLink href={profile['practice_website']}>
                        <p className='dentist-website'>{profile['practice_website']}</p>
                      </ExtLink>
                    )
                  }
                </Col>
                {
                  offices.map((off, i) => {
                    let streetAddress = off['office_address_1']

                    if (off['office_address_2']) {
                      streetAddress += `, ${off['office_address_2']}`
                    }

                    return (
                      <Col sm='4' key={i} className='dentist-office-info'>
                        {
                          off['office_name'] && <div className='dentist-office-city'>{off['office_name']}</div>
                        }
                        {
                          streetAddress && <div className='dentist-office-detail'>{streetAddress}</div>
                        }
                        {
                          off['office_city'] && off['office_state'] && <div className='dentist-office-detail'>{off['office_city']}, {off['office_state']} {off['office_zip']}</div>
                        }
                        {
                          off['office_phone'] && <div className='dentist-office-detail'>Main: {formatPhoneNumber(off['office_phone'])}</div>
                        }
                        {
                          off['office_fax'] && <div className='dentist-office-detail'>Fax: {formatPhoneNumber(off['office_fax'])}</div>
                        }
                        {
                          off['office_hours'] && Object.keys(off['parsed_office_hours']).length > 0 &&
                            <div>
                              <div className='dentist-office-detail'>Hours:</div>
                              <div style={{ 'marginLeft': '10px' }}>
                                {
                                  Object.keys(off['parsed_office_hours']).map((day, i) => <div key={i} className='dentist-office-detail'>{day}: {off['parsed_office_hours'][day]}</div>)
                                }
                              </div>
                            </div>
                        }
                      </Col>
                    )
                  })
                }
                <Col sm='12' className='office-map'>
                  <Button onClick={toggleModal} className='view-team-btn'>View Office Map</Button>
                  <Button className='btn btn-text small' href={`/practices/${profile['surgeon_group_slug']}`}>View Surgical Team</Button>
                </Col>
              </Row>
            </TabPane>
          </Tabs>
        </Col>
      </Row>
    </section>
  )
}

export default withFormatting(DentistInfoTabs)

import Home from './Home'
import { compose, lifecycle, withStateHandlers } from 'recompose'
import { api, notifier, storage } from 'lib'

/** ********** HOC **********/

export const enhance = compose(
  withStateHandlers({
    allSurgeonGroups: []
  }, {
    setSurgeonGroups: () => (payload) => ({ allSurgeonGroups: payload })
  }),
  lifecycle({
    componentWillMount () {
      const { history, setSurgeonGroups } = this.props
      const teamId = storage.get('selected_surgeon_group')
      if (teamId) {
        history.push(`/practices/${teamId}`)
      } else {
        api.get(`/directory/practices`)
          .then(resp => {
            setSurgeonGroups(resp['data'])
          })
          .catch(notifier.bad.bind(notifier))
      }
    }
  })
)

export default enhance(Home)

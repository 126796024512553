
/* eslint-disable camelcase */

import React from 'react'
import classnames from 'classnames'

const BlueBarTitle = ({ dir = 'left', negMargin = false, children, title, tabs = [], location }) => {
  // const queryParams = qs.parse(location.search, { ignoreQueryPrefix: true })
  // const { selectedTab } = queryParams

  return (
    <div className={classnames({ dirRight: dir === 'right' }, 'flex')}>
      <div className={'blue-bar negMarTop w60'} >
        {
          title &&
          <h2 className='doctor-single-tab title'>{title}</h2>
        }
      </div>
    </div>
  )
}

export default BlueBarTitle

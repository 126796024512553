
/* eslint-disable camelcase */

import React from 'react'
import {
  Button,
  Avatar,
  ExtLink
} from 'atoms'

const OfficeMapWindow = ({ avatar, user_name, slug, office_name, office_address_1, office_address_2, office_city, office_state, office_zip, office_phone, practice_website, user_id, doctor_profile_id, pathname }) => {
  let streetAddress = office_address_1

  if (office_address_2) {
    streetAddress += `, ${office_address_2}`
  }

  return (
    <div className='flex-column'>
      <div className='flex-row align-center'>
        {
          avatar &&
            <div>
              <Avatar img={avatar} />
            </div>
        }
        <div>
          <div className='strong'>{user_name}</div>
          <div className='strong'>{office_name}</div>
          <div>{streetAddress}</div>
          <div>{office_city}, {office_state} {office_zip}</div>
          <div>{office_phone}</div>
        </div>
      </div>
      <div className='flex center'>
        {/* TODO: use the doctor_profile_id instead of user_id */}
        {pathname === `/doctors/${slug}`
          ? <div>
            {practice_website
              ? <ExtLink href={practice_website}>
                <Button className='view-team'>Learn More</Button>
              </ExtLink>
              : <br />
            }
          </div>
          : <Button className='view-team' href={`/doctors/${user_id || doctor_profile_id || slug}`}>Learn More</Button>
        }
      </div>
    </div>
  )
}

export default OfficeMapWindow

import Auth from 'j-toker'
import PubSub from 'pubsub-js'

PubSub.subscribe('auth', function (e, msg) {
  console.log('Auth.user from PubSub', Auth.user)
  console.log('e', e)
  console.log('msg', msg)
})

Auth.configure({
  apiUrl: `${process.env.REACT_APP_API_ROOT_URL}/${process.env.REACT_APP_API_VERSION}`
})

class JTAuth {
  login (formData) {
    return Auth.emailSignIn(formData)
  }

  signup (formData) {
    return Auth.emailSignUp(formData)
  }

  logout () {
    return Auth.signOut()
  }

  get signedIn () {
    console.log('Auth.user.signedIn', Auth.user.signedIn)
    console.log('Auth.user', Auth.user)
    return Auth.user.signedIn
  }

  get authHeaders () {
    return Auth.retrieveData('authHeaders')
  }

  get user () {
    return Auth.user
  }

  get userRoles () {
    if (this.user.attributes && this.user.attributes.serialized_roles) {
      return this.user.attributes.serialized_roles
    } else {
      console.warn('Unable to locate attributes and/or roles for user:', this.user)
      return []
    }
  }

  /**
   * Check if the current user has a specific role
   * @param {string} roleName - Name of role to check for
   * @param {string} [modelName] - Name of model to check with role (global role check if == null, class-wide check if modelId == null)
   * @param {string|number} [modelId] - ID of model instance to check for role (specific model instance role check)
   */
  userHasRole (roleName, modelName = null, modelId = null) {
    // Search for role by name in serialized user roles
    const findRole = this.userRoles.filter(userRole => userRole['role'] === roleName)

    // If user has 1+ roles "roleName", search for model compare
    if (findRole.length > 0) {
      // Loop through all/any roles "roleName" on current user
      for (let i = 0; i < findRole.length; i++) {
        const userRole = findRole[i]

        // If user has "roleName" role, for no model/model_id, it is global role
        if (userRole['model'] === null && userRole['model_id'] === null) {
          return true
        // If user has "roleName" role & requested modelName, it is model (class) scoped role
        } else if (userRole['model_id'] === null && userRole['model'] === modelName) {
          return true
        // If user has "roleName" for exact modelName & modelId, it is model (instance) specific role
        } else if (userRole['model'] === modelName && userRole['model_id'] === parseInt(modelId, 10)) {
          return true
        }
      }
    }

    console.log('COULD NOT FIND ROLE FOR USER! role=', roleName, 'model=', modelName, 'model_id=', modelId, 'user=', this.user)

    // Could not find requested role
    return false
  }
}

const auth = new JTAuth()

export default auth

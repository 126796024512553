/* eslint-disable camelcase */

import React from 'react'
import {
  PracticeLogo,
  Row,
  Col
} from 'atoms'
import {
  ModelHeader
} from 'molecules'

const PracticeProfileInfo = ({ practice }) => {
  const {
    name,
    practice_logo
  } = practice

  return (
    <Row className='practice-info-section' >
      <Col sm='12'>
        <ModelHeader surgeon_group_name={name} />
      </Col>
      <Row className='practice-row-bottom flex-column center'>
        <PracticeLogo src={practice_logo} size='md' />
      </Row>
    </Row>
  )
}

export default PracticeProfileInfo

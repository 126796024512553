/* eslint-disable camelcase */

import React from 'react'
import {
  Row,
  Icon,
  Button
} from 'atoms'
import {
  FieldContainer
} from 'molecules'
import {
  Form
} from 'organisms'

const LocateTeamForm = ({ setLocateFormData, onSubmit }) => {
  return (
    <section className='locate-team-form'>
      <p className='title'>SELECT AN ASIRD SURGEON/DENTIST NEAR YOU</p>
      <Form handleChange={setLocateFormData}>
        <FieldContainer
          className='locate-form-input'
          type='text'
          name='zipcode'
          placeholder='Zipcode'
        />
        <div className='locate-form-or'>or</div>
        <Row>
          <FieldContainer
            type='text'
            name='city'
            placeholder='City'
            className='col'
          />

          <FieldContainer
            type='select'
            name='state'
            list='states'
            placeholder='State'
            className='col'
          />
        </Row>
      </Form>
      <Button onClick={onSubmit}>
        <Icon iconName='search' />
        Search
      </Button>
    </section>
  )
}

export default LocateTeamForm

/* eslint-disable camelcase */

import React from 'react'
import { compose } from 'recompose'
import { withRouter } from 'react-router-dom'
import { withFormatting } from 'helpers'

import {
  Row,
  Col,
  Button,
  Link,
  FeaturedImage
} from 'atoms'

const PracticeSurgeonListItem = ({ profile, formatPhoneNumber, formatFullTitle, history }) => {
  const {
    slug,
    profile_image,
    practice_name
  } = profile

  return (
    <Col xs='12' sm='6' md='6' lg='4' xl='3'>
      <Row className='profile-card-row'>
        <Col sm='12'>
          <FeaturedImage size='sm' src={profile_image} onClick={() => history.push(`/doctors/${slug}`)} />
        </Col>
        <Col sm='12' className='profile-card'>
          <Link to={`/doctors/${slug}`}>
            <h3 className='user-name'>{formatFullTitle(profile)}</h3>
          </Link>
          <p>{practice_name}</p>
          <Button className='learn-more-btn btn-sm' onClick={() => history.push(`/doctors/${slug}`)}>Learn More</Button>
        </Col>
      </Row>
    </Col>
  )
}

export default compose(
  withFormatting,
  withRouter
)(PracticeSurgeonListItem)

import React from 'react'
import { Marker as MarkerRGM } from 'react-google-maps'
import dentistMarker from './dentist-marker.png'
import surgeonMarker from './surgeon-marker.png'

const Marker = ({ childrenWithProps, children, toggleInfoWindow, isInfoWindowOpen, obj, markerId, activeMarker, setActiveMarker, ...rest }) => {
  return (
    <MarkerRGM
      // onMouseOver={toggleInfoWindow}
      onClick={() => setActiveMarker(markerId)}
      icon={{
        url: obj['doctor_type'] === 'dentist' ? dentistMarker : surgeonMarker, // pass your image here
        size: { width: 30, height: 100 },
        anchor: { x: 15, y: 50 },
        scaledSize: { width: 27, height: 45 }
      }}
      {...rest}
    >
      { markerId === activeMarker && childrenWithProps }
    </MarkerRGM>
  )
}

export default Marker

import { compose, withStateHandlers, withHandlers, withPropsOnChange } from 'recompose'
import { api, notifier, storage } from 'lib'
import { withFormatting } from 'helpers'
import { withMaybe } from '@bowtie/react-utils'
import TeamList from './TeamList'

const nullConditionFn = ({ practice = {} }) => !practice['surgeon_group_id']

export default compose(
  withFormatting,
  withMaybe(nullConditionFn),
  withStateHandlers({
    page: 1,
    teamList: [],
    pages: {},
    isTeamLoading: false
  }, {
    setPage: () => (payload) => ({ page: payload }),
    setTeamList: () => (payload) => ({ teamList: payload }),
    setPages: () => (payload) => ({ pages: payload }),
    setIsTeamLoading: () => (payload) => ({ isTeamLoading: payload })
  }),
  withHandlers({
    getTeam: ({ setDentists, team, setPages, setTeamList, practice, perPage, page, setIsTeamLoading, formatLocation }) => () => {
      const locationSort = storage.get('locate_form_data')
        ? formatLocation(storage.get('locate_form_data'))
        : `${practice['city']},${practice['zip']}`

      setIsTeamLoading(true)
      api.get(`directory/practice_doctors/${practice['surgeon_group_id']}?doctor_type=${team}&location_sort=${locationSort}&per_page=${perPage}&page=${page}`)
        .then(({ data, pages }) => {
          setTeamList(data)
          setPages(pages)
          setIsTeamLoading(false)
        })
        .catch(resp => {
          notifier.bad(resp)
          setIsTeamLoading(false)
        })
    },
    nextPage: ({ setPage, page, pages }) => () => {
      const { next } = pages
      setPage(Number.parseInt(next['page'], 10))
    },
    previousPage: ({ setPage, page, pages }) => () => {
      const { prev } = pages

      setPage(Number.parseInt(prev['page'], 10))
    },
    goToPage: ({ setPage }) => (page) => {
      setPage(page)
    }
  }),
  withPropsOnChange(['page'], ({ getTeam }) => {
    getTeam()
  })
)(TeamList)


import React from 'react'
import {
  Row,
  Col,
  Modal,
  ModalBody
} from 'atoms'
import {
  DoctorOfficeMap
} from 'organisms'
import { ModalHeader } from 'reactstrap'

const OfficeMapModal = ({ isModalOpen, toggleModal, profile, customParams, offices, ...rest }) => {
  return (
    <Modal isOpen={isModalOpen} toggle={toggleModal} size='lg'>
      <ModalHeader toggle={toggleModal}>
        <Row className='professional-title-section' style={{ marginLeft: '12px' }}>
          <Col sm='12' className='profile-title'>{profile['name']}</Col>
          <Col sm='12' className='profile-subtitle'>{profile['surgeon_group_name']}</Col>
        </Row>
      </ModalHeader>
      <ModalBody>
        <DoctorOfficeMap
          profile={profile}
          customParams={customParams}
          {...rest}
        />
      </ModalBody>
    </Modal>
  )
}

export default OfficeMapModal

// /* eslint-disable no-undef */
import RequestConsultationForm from './RequestConsultationForm'
import { api, notifier } from 'lib'
import { withFormatting } from 'helpers'
import { compose, withHandlers, withState } from 'recompose'

export default compose(
  withFormatting,
  withState('formErrors', 'setFormErrors', {}),
  withState('recaptchaResult', 'setRecaptchaResult', false),
  withHandlers({
    handleRecaptchaSubmit: ({ setRecaptchaResult }) => (value) => {
      const recaptchaValue = value
      // console.log('RECAPTCHA VALUE: ', recaptchaValue)

      var requestOptions = {
        headers: {
          'Content-Type': 'application/json'
        }
      }

      api.post('/public/recaptcha', { response: recaptchaValue }, requestOptions)
        .then(({ data }) => {
          // console.log('Recaptcha Response: ', data)
          setRecaptchaResult(data['success'])
        })
        .catch(notifier.bad.bind(notifier))
    },
    handleSubmit: ({ modelId, modelName, setFormErrors }) => (formData, e, setFormData) => {
      Object.assign(formData, { 'model_name': modelName, 'requested_slug': modelId })

      setFormErrors({})

      var requestOptions = {
        headers: {
          'Content-Type': 'application/json'
        }
      }

      api.post(`/directory/consultations`, formData, requestOptions)
        .then(({ data }) => {
          console.log('Consultation successfully sent!', data)
          notifier.success('Consultation Request successfully sent!')
          setFormData({})
        })
        .catch(resp => {
          notifier.apiErrors(resp, setFormErrors)
        })
    }
  })
)(RequestConsultationForm)

// /* eslint-disable no-undef */
import LocateTeam from './LocateTeam'
import { api, notifier, storage } from 'lib'
import { compose, withHandlers, withStateHandlers, lifecycle } from 'recompose'

export default compose(

  withStateHandlers({
    locateFormData: {},
    surgeonGroups: [],
    isModalOpen: false,
    isTeamSearchLoading: false
  }, {
    setLocateFormData: () => (e, formData) => {
      if (e) {
        const key = e.target.name
        const value = e.target.value
        value === '' && delete formData[key]
        return { locateFormData: formData }
      } else {
        return { locateFormData: formData }
      }
    },
    setSurgeonGroups: () => (payload) => ({ surgeonGroups: payload }),
    toggleModal: ({ isModalOpen }) => () => ({ isModalOpen: !isModalOpen }),
    setIsTeamSearchLoading: () => (payload) => ({ isTeamSearchLoading: payload })
  }),
  withHandlers({
    getSurgicalTeams: ({ setSurgeonGroups, toggleModal, setIsTeamSearchLoading }) => (location) => {
      toggleModal()
      setIsTeamSearchLoading(true)
      api.get(`/directory/practices?location[radius]=300&location[query]=${Object.values(location).join(',')}`)
        .then(({ data, meta }) => {
          setSurgeonGroups(data)
          setIsTeamSearchLoading(false)
          storage.set('location_coordinates', meta['geo_center'])
        })
        .catch(resp => {
          notifier.bad(resp)
          setIsTeamSearchLoading(false)
        })
    }
  }),
  withHandlers({
    onSubmit: ({ locateFormData, getSurgicalTeams }) => () => {
      if (Object.keys(locateFormData).length > 0) {
        const location = Object.assign({ city: locateFormData['state'] ? 'state' : 'zip' }, locateFormData)
        storage.set('locate_form_data', locateFormData)
        getSurgicalTeams(location)
      }
    },
    selectTeam: ({ history, locateFormData }) => (id) => {
      storage.set('selected_surgeon_group', id)
      history.push(`/practices/${id}`)
    }
  }),
  lifecycle({
    componentWillMount () {
      const { queryParams, getSurgicalTeams, setLocateFormData } = this.props
      if (queryParams['location_form_zip']) {
        setLocateFormData(null, { zipcode: queryParams['location_form_zip'] })
        getSurgicalTeams({ zipcode: queryParams['location_form_zip'] })
      }
    }
  })
)(LocateTeam)

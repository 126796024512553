/* eslint-disable camelcase */

import React from 'react'
import { titleize } from '@bowtie/utils'
import { Title } from 'atoms'

const ProfessionalTitle = ({ name, specialty }) => {
  return (
    <div className='professional-title-section'>
      <Title className='profile-title'>{name}</Title>
      <h2 className='profile-specialty'>{specialty && titleize(specialty, '_').toUpperCase()}</h2>
    </div>
  )
}

export default ProfessionalTitle

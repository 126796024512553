import Api from '@bowtie/api'
// import airbrake from './airbrake'
import storage from './storage'
import notifier from './notifier'
import parseLinkHeader from 'parse-link-header'
import jsonapi from 'jsonapi-parse'
import { auth } from './'

const api = new Api({
  root: process.env.REACT_APP_API_ROOT_URL,
  version: process.env.REACT_APP_API_VERSION,
  secureOnly: process.env.REACT_APP_ENV === 'production',
  verbose: process.env.REACT_APP_ENV !== 'production',
  authorization: 'Custom',
  defaultOptions: {
    headers: {
      'Content-Type': 'application/vnd.api+json'
    }
  }
})

api.authorize({
  headers: () => auth.authHeaders,
  validate: () => auth.signedIn
  // token: () => storage.get('access_token')
})

const handleApiUnauthorized = (resp) => {
  notifier.pubnub && notifier.pubnub.stop()
  storage.clear()
  // window.location.reload()

  if (window.location.pathname !== '/') {
    window.location = '/'
  }
}

// Handler for all non 2xx code api responses
const handleApiError = (resp) => {
  console.warn('API Error', resp)

  // Airbrake severity is warning unless response status was 5xx
  const severity = /^5\d\d$/.test(resp.status) ? 'error' : 'warn'
  let errorTitle = `${resp.status} ${resp.statusText}`

  if (resp.data && resp.data.message && resp.data.message.trim() !== '') {
    errorTitle = resp.data.message
  }

  // airbrake.notify({
  //   error: new Error(`API ${severity}: ${errorTitle}`),
  //   context: {
  //     severity,
  //     resp
  //   }
  // })
  console.error(`API ${severity}: ${errorTitle}`)
}

// Attach handlers to event emitter by string event name
api.on('error', handleApiError)
api.on('401', handleApiUnauthorized)

api.on('404', (resp) => {
  console.log('404 API ERROR: ', resp)

  // const { url } = resp
  // /(doctors|practices)/\/\g/.test(url)

  if (window.location.pathname !== '/404') {
    window.location = '/404'
  }
})

api.use(async (response) => {
  try {
    const data = await response.json()
    console.log('original data: ', data)
    response.originalData = Object.assign({}, data)
    Object.assign(response, jsonapi.parse(data))
  } catch (e) {
    return Promise.resolve(response)
  }

  response.pages = {}

  if (response.headers.get('link')) {
    response.pages = Object.assign(response.pages, parseLinkHeader(response.headers.get('link')))
  }

  if (response.headers.get('total')) {
    response.pages.total = parseInt(response.headers.get('total'), 10)
  }

  if (response.headers.get('page')) {
    response.pages['page'] = parseInt(response.headers.get('page'), 10)
    response.pages['pageCount'] = parseInt((response.pages['last'] ? response.pages['last'].page : response.pages['page']), 10)
  }

  if (response.headers.get('per-page')) {
    response.pages['perPage'] = parseInt(response.headers.get('per-page'), 10)
  }

  return Promise.resolve(response)
})

export default api

/* eslint-disable camelcase */

import React from 'react'
import {
  Col,
  Row
} from 'atoms'
import {
  GoogleMap,
  OfficeMapWindow
} from 'molecules'

const DoctorOfficeMap = ({ doctorOffices, locationCoordinates, locationCenter, boundingBox, profile, location, formatPhoneNumber }) => {
  return (
    <section>
      <Row>
        <Col sm='4' className='dentist-office-section'>
          {
            doctorOffices.map((off, i) => {
              let streetAddress = off['office_address_1']

              if (off['office_address_2']) {
                streetAddress += `, ${off['office_address_2']}`
              }

              return (
                <Row key={i} className='flex-column dentist-office-info doctor-office-modal-section office-infowindow' style={{ paddingLeft: `30px` }}>
                  <p className='dentist-office-name'>{off['office_name']}</p>
                  <p className='dentist-office-detail'>{streetAddress}</p>
                  <p className='dentist-office-detail'>{off['office_city']}, {off['office_state']} {off['office_zip']}</p>
                  {
                    off['office_phone'] && off['office_phone'].trim() !== '' && (
                      <p className='dentist-office-detail'>{formatPhoneNumber(off['office_phone'])}</p>
                    )
                  }
                </Row>
              )
            })
          }
        </Col>
        <Col sm='8'>
          <GoogleMap
            zoom={9}
            defaultCenter={{ lat: locationCenter[0], lng: locationCenter[1] }}
            markers={doctorOffices}
            infoWindow={OfficeMapWindow}
            bounds={boundingBox}
            {...profile}
            {...location}
          />
        </Col>
      </Row>

    </section>
  )
}

export default DoctorOfficeMap

// /* eslint-disable no-undef */
import React from 'react'
import Marker from './Marker'
import { compose, withProps, withStateHandlers } from 'recompose'

export default compose(
  withStateHandlers({
    isInfoWindowOpen: false
  }, {
    toggleInfoWindow: ({ isInfoWindowOpen }) => () => ({ isInfoWindowOpen: !isInfoWindowOpen })
  }),
  withProps(({ children, toggleInfoWindow, setActiveMarker, ...rest }) => {
    const childrenWithProps = React.Children.map(children, child =>
      React.cloneElement(child, {
        // onMouseOut: () => toggleInfoWindow,
        onCloseClick: () => setActiveMarker(null)
      })
    )
    return { childrenWithProps }
  })
)(Marker)

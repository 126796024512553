
// Containers should include all logic that enhances a component
// this includes any reduce methods, recompose, or middleware.

import { compose, withHandlers, withPropsOnChange } from 'recompose'
import qs from 'qs'
import { storage } from 'lib'
import { withRouter } from 'react-router'

export default compose(
  withRouter,
  withPropsOnChange(
    ({ location = {} }, { location: nextLocation = {} }) => nextLocation.search !== location.search,
    ({ location = {} }) => ({ queryParams: qs.parse(location.search, { ignoreQueryPrefix: true }) })
  ),
  withHandlers({
    findLocationCoordinates: ({ queryParams }) => (defaultLocation) => {
      let locationCoordinates
      if (queryParams['map_location']) {
        locationCoordinates = queryParams['map_location']
      } else if (storage.get('location_coordinates')) {
        locationCoordinates = storage.get('location_coordinates')
      } else {
        locationCoordinates = defaultLocation
      }
      return locationCoordinates
    }
  })
)

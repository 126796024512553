import React from 'react'

const Body = ({ children }) => {
  return (
    <section className='body-template'>
      { children }
    </section>
  )
}

export default Body

/* eslint-disable camelcase */

import React from 'react'
import PropTypes from 'prop-types'
import pluralize from 'pluralize'
// import { capitalizeWord } from '@bowtie/utils'
import { Link } from 'atoms'

const ModelListItem = ({ model }) => {
  const { name, slug, surgeon_group_id, type } = model
  return (
    <div>
      <Link to={`/${pluralize(type)}/${slug || surgeon_group_id}`}>{name}</Link>
    </div>
  )
}

ModelListItem.propTypes = {
  model: PropTypes.shape({
    name: PropTypes.string,
    type: PropTypes.string,
    slug: PropTypes.string
  })
}

export default ModelListItem

import React from 'react'
import classnames from 'classnames'
import { BeatLoader } from 'react-spinners'
import { css } from 'react-emotion'

const override = css`
  margin: 50px auto;
  display: flex;
  align-items: flex-start;
  justify-content: center;
`

const TeamLoader = ({ isLoading = true }) => {
  return (
    <div className={classnames({ isLoading }, 'sweet-loading')}>
      <BeatLoader
        className={override}
        sizeUnit={'px'}
        size={20}
        color={'#0099bf'}
        loading={isLoading}
      />
    </div>
  )
}

export default TeamLoader

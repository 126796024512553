/* eslint-disable camelcase */

import React from 'react'
import { Link } from 'atoms'
import { storage } from 'lib'

const ModelHeader = ({ surgeon_group_city = '', surgeon_group_name = '', title, practice_profile }) => {
  return (
    <div className='model-header-container'>
      <div className='model-header-section'>
        <div className='model-header-link-container'>
          <span className='asird-title'>ASIRD</span>
          <span className='practice-title'>{title || surgeon_group_name}</span>
          {
            surgeon_group_name &&
            <p className='city-title'>[<Link to={'/'} onClick={() => storage.clear()}>change</Link>]</p>
          }

        </div>
      </div>
    </div>
  )
}

export default ModelHeader

import React from 'react'
import { Switch } from 'react-router-dom'
import { PublicRoute, Body } from 'atoms'
import {
  Home,
  Practices,
  Doctors,
  Notifications,
  PageNotFound
} from 'ecosystems'
import { Header, Footer } from 'organisms'

const App = (props) => {
  return (
    <section className='app'>
      <Notifications />
      <Header />
      <Body>
        <Switch>
          <PublicRoute
            exact
            props={props}
            path='/'
            component={Home}
          />
          <PublicRoute
            props={props}
            path={`/:modelName(doctors)/:modelId?`}
            component={Doctors}
          />
          <PublicRoute
            props={props}
            path={`/:modelName(practices)/:modelId?`}
            component={Practices}
          />
          <PublicRoute
            props={props}
            component={PageNotFound}
          />
        </Switch>
      </Body>
      <Footer />
    </section>
  )
}

export default App

import React from 'react'

export default () => {
  return (
    <section className='error-page section text-center logo-bg'>
      <h1>Unknown Error</h1>
      <h2>OOPS! There was an issue loading this page.</h2>
      <p>This issue has been tracked and will be reviewed.</p>
      <br />
      <a className='btn btn-default' href='/'>Return to Home</a>
    </section>
  )
}

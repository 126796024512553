import React from 'react'
import styles from './GoogleMapStyles'
import { Marker } from 'molecules'
import { GoogleMap as GoogleMapRGM, InfoWindow } from 'react-google-maps'

const GoogleMap = ({ zoom, defaultCenter, markers = [], infoWindow: Component, onMapMounted, onTilesLoaded, setActiveMarker, activeMarker, ...rest }) => {
  return (
    <GoogleMapRGM
      defaultZoom={zoom}
      defaultCenter={defaultCenter}
      defaultOptions={{ styles }}
      ref={onMapMounted}
      onTilesLoaded={onTilesLoaded}
    >
      {
        markers.filter(m => m['latitude'] && m['longitude']).map((obj, i) => {
          return (
            <Marker
              key={i}
              position={{ lat: obj['latitude'], lng: obj['longitude'] }}
              obj={obj}
              markerId={i}
              setActiveMarker={setActiveMarker}
              activeMarker={activeMarker}
            >
              {
                Component &&
                  <InfoWindow>
                    <Component {...obj} {...rest} />
                  </InfoWindow>
              }
            </Marker>
          )
        })
      }
    </GoogleMapRGM>
  )
}

export default GoogleMap

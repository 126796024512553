import React from 'react'

const PracticeLogo = ({ src, className, size = 'lg', ...rest }) => {
  return (
    <div className={`featured-logo ${className || ''}`} {...rest} >
      <img alt='featured' className={`featured-logo ${size}`} src={src} />
    </div>
  )
}

export default PracticeLogo

import React from 'react'
import { DropdownItem, Navbar, Collapse, NavbarToggler, NavbarBrand, NavItem, NavLink } from 'reactstrap'
import {
  Nav,
  AsirdLogo,
  Button,
  DropdownMenuItem,
  ExtLink
} from 'atoms'

const Header = ({ handleFindTeam, history, isCollapseOpen, toggleCollapse }) => {
  return (
    <Navbar id='nav' light expand='lg'>
      <NavbarBrand target='_blank' href={process.env.REACT_APP_BASE_MARKETING_URL}>
        <AsirdLogo />
      </NavbarBrand>
      <NavbarToggler onClick={toggleCollapse} size='lg'>
        <span className='sr-only'>Toggle navigation</span>
        <span className='fa fa-bars fa-lg' />
        <span>Menu</span>
      </NavbarToggler>
      <Collapse isOpen={isCollapseOpen} className='' navbar>
        <Nav navbar className='ml-auto' >
          <NavItem onClick={handleFindTeam}>
            <NavLink className='btn-text'>Find a Team</NavLink>
          </NavItem>
          <DropdownMenuItem title={'About'}>
            <ExtLink href={`${process.env.REACT_APP_BASE_MARKETING_URL}about/`}>
              <DropdownItem>
                  About ASIRD
              </DropdownItem>
            </ExtLink>
            <ExtLink href={`${process.env.REACT_APP_BASE_MARKETING_URL}about/why-asird/`}>
              <DropdownItem>
                  Why ASIRD?
              </DropdownItem>
            </ExtLink>
            <ExtLink href={`${process.env.REACT_APP_BASE_MARKETING_URL}about/featured-speakers/`}>
              <DropdownItem>
                  Featured ASIRD Speakers
              </DropdownItem>
            </ExtLink>
            <ExtLink href={`${process.env.REACT_APP_BASE_MARKETING_URL}about/membership-criteria/`}>
              <DropdownItem>
                  Membership Criteria
              </DropdownItem>
            </ExtLink>
            <ExtLink href={`${process.env.REACT_APP_BASE_MARKETING_URL}become-a-member/`}>
              <DropdownItem>
                  Become a Member
              </DropdownItem>
            </ExtLink>
          </DropdownMenuItem>

          <DropdownMenuItem title={'Dental Implants'}>
            <ExtLink href={`${process.env.REACT_APP_BASE_MARKETING_URL}dental-implants/`}>
              <DropdownItem>
              About Dental Implants
              </DropdownItem>
            </ExtLink>
            <ExtLink href={`${process.env.REACT_APP_BASE_MARKETING_URL}dental-implants/benefits/`}>
              <DropdownItem>
              Benefits of Dental Implants
              </DropdownItem>
            </ExtLink>
            <ExtLink href={`${process.env.REACT_APP_BASE_MARKETING_URL}dental-implants/what-to-expect/`}>
              <DropdownItem>
              What To Expect
              </DropdownItem>
            </ExtLink>
            <ExtLink href={`${process.env.REACT_APP_BASE_MARKETING_URL}dental-implants/implant-options/`}>
              <DropdownItem>
              Dental Implant Options
              </DropdownItem>
            </ExtLink>
            <ExtLink href={`${process.env.REACT_APP_BASE_MARKETING_URL}dental-implants/advanced-technology/`}>
              <DropdownItem>
              Advanced Technology
              </DropdownItem>
            </ExtLink>
            <ExtLink href={`${process.env.REACT_APP_BASE_MARKETING_URL}dental-implants/dental-implant-maintenance/`}>
              <DropdownItem>
              Dental Implant Maintenance
              </DropdownItem>
            </ExtLink>
            <ExtLink href={`${process.env.REACT_APP_BASE_MARKETING_URL}dental-implants/patient-stories/`}>
              <DropdownItem>
              Patient Stories
              </DropdownItem>
            </ExtLink>
            <ExtLink href={`${process.env.REACT_APP_BASE_MARKETING_URL}dental-implants/gallery/`}>
              <DropdownItem>
              Before and After Gallery
              </DropdownItem>
            </ExtLink>
          </DropdownMenuItem>
          <DropdownMenuItem title={'Implant Options'}>
            <ExtLink href={`${process.env.REACT_APP_BASE_MARKETING_URL}implant-options/`}>
              <DropdownItem>
              Conditions
              </DropdownItem>
            </ExtLink>
            <ExtLink href={`${process.env.REACT_APP_BASE_MARKETING_URL}implant-options/back-tooth-replacement/`}>
              <DropdownItem>
              Back Tooth Replacement
              </DropdownItem>
            </ExtLink>
            <ExtLink href={`${process.env.REACT_APP_BASE_MARKETING_URL}implant-options/failing-bridge/`}>
              <DropdownItem>
              Failing Bridge
              </DropdownItem>
            </ExtLink>
            <ExtLink href={`${process.env.REACT_APP_BASE_MARKETING_URL}implant-options/front-tooth-replacement/`}>
              <DropdownItem>
              Front Tooth Replacement
              </DropdownItem>
            </ExtLink>
            <ExtLink href={`${process.env.REACT_APP_BASE_MARKETING_URL}implant-options/non-fitting-dentures/`}>
              <DropdownItem>
              Non Fitting Dentures
              </DropdownItem>
            </ExtLink>

          </DropdownMenuItem>
          <ExtLink href={process.env.REACT_APP_BASE_ADMIN_URL}>
            <Button className='btn-sm btn-sign-in'>Sign in </Button>
          </ExtLink>
        </Nav>
      </Collapse>
    </Navbar>
  )
}

export default Header

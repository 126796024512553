import React from 'react'
import Doctors from './Doctors'
import qs from 'qs'
import { withRouter } from 'react-router-dom'
import { compose, withHandlers, withPropsOnChange, withStateHandlers } from 'recompose'
import { api, notifier } from 'lib'
import { withFormatting, withParamStorageParsing, withTracking } from 'helpers'
import { withEither } from '@bowtie/react-utils'
import { DoctorLoader } from 'atoms'

const emptyConditionFn = ({ doctor }) => !doctor || !doctor.id

const doctorLoader = () => (<DoctorLoader isLoading />)

const days = [
  'monday',
  'tuesday',
  'wednesday',
  'thursday',
  'friday',
  'saturday',
  'sunday'
]

const aliases = {
  monday: 'Mon',
  tuesday: 'Tues',
  wednesday: 'Wed',
  thursday: 'Thurs',
  friday: 'Fri',
  saturday: 'Sat',
  sunday: 'Sun'
}

export default compose(
  withRouter,
  withTracking,
  withFormatting,
  withParamStorageParsing,
  withStateHandlers({
    doctor: {},
    surgeonGroup: {},
    practiceSurgeons: [],
    practiceDentists: [],
    offices: [],
    isModalOpen: false
  }, {
    setDoctor: () => (payload) => ({ doctor: payload }),
    setSurgeonGroup: () => (payload) => ({ surgeonGroup: payload }),
    setDentists: () => (payload) => ({ practiceDentists: payload }),
    setOffices: () => (payload) => ({ offices: payload }),
    setSurgeons: () => (payload) => ({ practiceSurgeons: payload }),
    toggleModal: ({ isModalOpen }) => () => ({ isModalOpen: !isModalOpen })
  }),
  withHandlers({
    parseOffice: ({ isJson, formattedTime }) => (office) => {
      const parsedOfficeHours = office['office_hours'] ? isJson(office['office_hours']) : {}
      const officeHours = {}

      days.forEach((day, i) => {
        const lastKey = Object.keys(officeHours).length ? Object.keys(officeHours).pop() : null
        const prevDay = i !== 0 ? days[i - 1] : day
        const dayObjExists = parsedOfficeHours[day] && parsedOfficeHours[day]['open'] && parsedOfficeHours[day]['close']
        const prevDayObjExists = parsedOfficeHours[prevDay] && parsedOfficeHours[prevDay]['open'] && parsedOfficeHours[prevDay]['close']
        if (i > 0 && lastKey && dayObjExists && prevDayObjExists && formattedTime(parsedOfficeHours[day]['open']) === formattedTime(parsedOfficeHours[prevDay]['open']) && formattedTime(parsedOfficeHours[day]['close']) === formattedTime(parsedOfficeHours[prevDay]['close'])) {
          const splitKey = lastKey.split('-')
          const newKey = `${splitKey[0]}-${aliases[day]}`

          officeHours[newKey] = officeHours[lastKey]
          delete officeHours[lastKey]
        } else {
          officeHours[aliases[day]] = (parsedOfficeHours[day] && parsedOfficeHours[day]['open'] && parsedOfficeHours[day]['close']) ? `${formattedTime(parsedOfficeHours[day]['open'])} - ${formattedTime(parsedOfficeHours[day]['close'])}` : 'Closed'
        }
      })
      office['parsed_office_hours'] = officeHours
      return office
    }
  }),
  withHandlers({
    getPracticeSurgeons: ({ setSurgeons }) => (id) => {
      api.get(`directory/practice_doctors/${id}?doctor_type=surgeon`)
        .then(({ data }) => {
          setSurgeons(data)
        })
    },
    getPracticeDentists: ({ setDentists }) => (id) => {
      api.get(`directory/practice_doctors/${id}?doctor_type=dentist`)
        .then(({ data }) => {
          setDentists(data)
        })
    },
    getOffices: ({ setOffices, parseOffice, findLocationCoordinates, doctor }) => (id) => {
      const locationCoordinates = findLocationCoordinates(doctor['surgeon_group_zip'])

      api.get(`directory/offices?user_id=${id}&location=${locationCoordinates}`)
        .then(({ data }) => {
          const parsedOffices = data.map(off => parseOffice(off))
          setOffices(parsedOffices)
        })
    },
    getPractice: ({ setSurgeonGroup, queryParams }) => (id) => {
      api.get(`/directory/practices/${id}`)
        .then(({ data }) => {
          setSurgeonGroup(data)
        })
        .catch((resp) => {
          console.log('resp', resp.errors)
          notifier.bad(resp)
        })
    }

  }),
  withHandlers({
    getDoctor: ({ setDoctorsList, setDoctor, getPracticeSurgeons, getPracticeDentists, getOffices, getPractice, setIsModelLoading, match, queryParams }) => () => {
      const { modelId } = match.params
      api.get(`/directory/doctors/${modelId}?${qs.stringify(queryParams)}`)
        .then(({ data, pages, meta }) => {
          setDoctor(data)
          getOffices(data['slug'])

          if (data['surgeon_group_id']) {
            getPracticeSurgeons(data['surgeon_group_id'])
            getPracticeDentists(data['surgeon_group_id'])
            getPractice(data['surgeon_group_id'])
          }
        })
        .catch((resp) => {
          console.log('resp', resp.errors)
          notifier.bad(resp)
        })
    }
  }),
  withPropsOnChange(['match'], ({ getDoctor }) => {
    console.log('GETTING NEW DOCTOR')
    getDoctor()
  }),
  withPropsOnChange([ 'doctor' ], ({ doctor, onPageViewTracking }) => {
    if (doctor && doctor.name) {
      onPageViewTracking({
        category: 'Doctor View',
        label: doctor.name
      })
    }
  }),
  withEither(emptyConditionFn, doctorLoader)
)(Doctors)

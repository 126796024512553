import React from 'react'
import { BrowserRouter } from 'react-router-dom'
import { AppContainer } from 'environments'
import { Switch, PublicRoute } from 'atoms'

const Routes = (props) => {
  return (
    <BrowserRouter>
      <Switch>
        <PublicRoute
          props={props}
          path='/'
          component={AppContainer}
        />
      </Switch>
    </BrowserRouter>
  )
}

export default Routes

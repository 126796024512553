import React from 'react'
import {
  BlueBarTitle,
  Quote,
  ModelHeader
} from 'molecules'
import {
  LocateTeam
} from 'organisms'
import {
  Row,
  Col,
  Button,
  ExtLink
} from 'atoms'

const Home = ({ getDoctorList, getPracticeList, doctorList = [], practiceList = [], ...rest }) => {
  return (
    <section className='home'>
      <ModelHeader title='LOCATE' />
      <LocateTeam {...rest} />
      <BlueBarTitle title='WHY ASIRD' dir='right' />

      <Row className='profile-extra-info-section align-items-center'>
        <Col sm='12' md='6' lg='4'>
          <Quote readMoreBtn />

        </Col>
        <Col sm='12' md='6' lg='8'>
          <p>When you work with an ASIRD dentist, you know that your dentist has completed extensive qualified continuing education in order to provide up-to-date dental care in a safe and comfortable manner.</p>
          <p>ASIRD dentists are required to restore a minimum of five dental implant cases each year in order to maintain their membership. They must also complete extensive didactic and hands-on clinical training in technology, aesthetics and dental health. ASIRD dentists complete a rigorous clinical peer review process, which requires a high degree of commitment and skill. These qualifications of membership are how you know your ASIRD dentist is experienced in advanced procedures, techniques, and products, and will provide up-to-date dental care in a safe and comfortable manner. Through their commitment to education and technology, ASIRD teams are able to provide optimal treatment and quality dental care.</p>
          <ExtLink href={`${process.env.REACT_APP_BASE_MARKETING_URL}about/`}>
            <Button className='btn-info'>Learn More</Button>
          </ExtLink>
        </Col>
      </Row>
    </section>
  )
}

export default Home

import { compose, withStateHandlers, withHandlers, withPropsOnChange } from 'recompose'
import { api, notifier, storage } from 'lib'
import { withFormatting } from 'helpers'

import Carousel from './Carousel'

export default compose(
  withFormatting,
  withStateHandlers(({ slidesToShow }) => ({
    teamList: [],
    responsivePerPage: null,
    isTeamLoading: false
  }), {
    setTeamList: () => (payload) => ({ teamList: payload }),
    setResponsivePerPage: () => (payload) => ({ responsivePerPage: payload }),
    setIsTeamLoading: () => (payload) => ({ isTeamLoading: payload })
  }),
  withHandlers({
    getTeam: ({ setDentists, team, setPages, setTeamList, practice, perPage, page, setIsTeamLoading, formatLocation }) => () => {
      setIsTeamLoading(true)

      const locationSort = storage.get('locate_form_data')
        ? formatLocation(storage.get('locate_form_data'))
        : `${practice['city']},${practice['zip']}`

      api.get(`directory/practice_doctors/${practice['surgeon_group_id']}?doctor_type=${team}&location_sort=${locationSort}&per_page=0`)
        .then(({ data }) => {
          setTeamList(data)
          setIsTeamLoading(false)
        })
        .catch(resp => {
          notifier.bad(resp)
          setIsTeamLoading(false)
        })
    },
    resizeHandler: ({ setResponsivePerPage }) => () => {
      if (window.innerWidth > 1491) {
        setResponsivePerPage(4)
      } else if (window.innerWidth > 1200 && window.innerWidth <= 1491) {
        setResponsivePerPage(3)
      } else if (window.innerWidth > 830 && window.innerWidth <= 1200) {
        setResponsivePerPage(2)
      } else if (window.innerWidth <= 830) {
        setResponsivePerPage(1)
      }
    }
  }),
  withPropsOnChange(['page'], ({ getTeam, resizeHandler }) => {
    resizeHandler()
    getTeam()
  })
)(Carousel)

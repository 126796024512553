/* eslint-disable camelcase */

import React from 'react'
import {
  Row,
  Col
} from 'atoms'
import {
  BlueBarTitle,
  ProfileListItem
} from 'molecules'

const SurgicalTeam = ({ surgeons, profile }) => {
  return (
    <section className='surgical-team-section logo-bg'>
      <BlueBarTitle
        title='SURGICAL TEAM'
      />
      <Row className='profile-extra-info-section'>
        <Col sm='12'>
          {profile['salutation']} {profile['name']}'s surgical team has years of experience serving {profile['surgeon_group_city']} and the surrounding area.
        </Col>

        {
          surgeons.map((sur, i) => {
            return (
              <Col key={i} xs='12' md='6' sm='6' lg='3' className='surgeon-card'>
                <ProfileListItem profile={sur} />
              </Col>
            )
          })
        }

      </Row>
    </section>
  )
}

export default SurgicalTeam

/* eslint-disable camelcase */

import React from 'react'
import { compose } from 'recompose'
import { withRouter } from 'react-router-dom'
import { withFormatting } from 'helpers'

import {
  Row,
  Col,
  Button,
  FeaturedImage
} from 'atoms'

const PracticeDentistListItem = ({ profile, formatPhoneNumber, formatFullTitle, history }) => {
  const {
    // office_address_2,
    slug,
    office_address_1,
    office_address_2,
    avatar,
    // profile_image,
    office_city,
    office_phone,
    office_state,
    office_zip,
    practice_name
  } = profile

  let streetAddress = office_address_1

  if (office_address_2) {
    streetAddress += `, ${office_address_2}`
  }

  return (
    <Col xs='12' sm='6' md='6' lg='3' className='dentist-card'>
      <Row>
        <div className='profile-card-row'>
          <Col xs='12' sm='12'>
            <FeaturedImage size='xs' src={avatar} onClick={() => history.push(`/doctors/${slug}`)} />
          </Col>
          <Col xs='12' sm='12'>
            <div className='team-profile-card'>
              <h3 className='user-name'>{formatFullTitle(profile)}</h3>
              <p>{practice_name}</p>
              <p>{streetAddress}</p>
              <p>{office_city}, {office_state} {office_zip}</p>
              {office_phone && <p>{formatPhoneNumber(office_phone)}</p>}
              <Button className='learn-more-btn btn-sm' onClick={() => history.push(`/doctors/${slug}`)}>Learn More</Button>
            </div>
          </Col>
        </div>
      </Row>
    </Col>
  )
}

export default compose(
  withFormatting,
  withRouter
)(PracticeDentistListItem)

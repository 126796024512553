/* eslint-disable camelcase */

import React from 'react'

/** ********** BASE COMPONENT **********/

const PageNotFound = (props) => {
  return (
    <section className='error-page section text-center logo-bg'>
      <h1>404 - Not Found</h1>
      <h2>OOPS! The page you are looking for cannot be found. </h2>
      <br />
      <a className='btn btn-default' href='/'>Return to Home</a>
    </section>
  )
}

export default PageNotFound

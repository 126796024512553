import React from 'react'
import {
  UncontrolledDropdown,
  DropdownToggle,
  DropdownMenu
} from 'reactstrap'

const DropdownMenuItem = ({ title, children }) => {
  return (
    <section>
      <UncontrolledDropdown nav inNavbar>
        <DropdownToggle nav caret>
          {title}
        </DropdownToggle>
        <DropdownMenu>
          { children }
        </DropdownMenu>
      </UncontrolledDropdown>
    </section>
  )
}

export default DropdownMenuItem

/* eslint-disable camelcase */

import React from 'react'
// import {
//   Button
// } from 'atoms'
// import {
//   FieldContainer
// } from 'molecules'
// import {
//   Form
// } from 'organisms'
// import ReCAPTCHA from 'react-google-recaptcha'

const RequestConsultationForm = ({ offices, formatPhoneNumber, practice, ...props }) => {
  // const { handleSubmit, formErrors, handleRecaptchaSubmit, recaptchaResult } = props;

  if (offices) {
    return (
      <div className='consultation-form'>
        <div className='schedule-consultation'>Contact Us</div>
        {
          offices.map((off, i) => {
            let streetAddress = off['office_address_1']

            if (off['office_address_2']) {
              streetAddress += `, ${off['office_address_2']}`
            }

            return (
              <div className='dentist-office-info'>
                {
                  off['office_name'] && <div className='dentist-office-city'>{off['office_name']}</div>
                }
                {
                  streetAddress && <div className='dentist-office-detail'>{streetAddress}</div>
                }
                {
                  off['office_city'] && off['office_state'] && <div className='dentist-office-detail'>{off['office_city']}, {off['office_state']} {off['office_zip']}</div>
                }
                {
                  off['office_phone'] && <div className='dentist-office-detail'>Main: {formatPhoneNumber(off['office_phone'])}</div>
                }
                {
                  off['office_fax'] && <div className='dentist-office-detail'>Fax: {formatPhoneNumber(off['office_fax'])}</div>
                }
                {
                  off['office_hours'] && Object.keys(off['parsed_office_hours']).length > 0 &&
                    <div>
                      <div className='dentist-office-detail'>Hours:</div>
                      <div style={{ 'marginLeft': '10px' }}>
                        {
                          Object.keys(off['parsed_office_hours']).map((day, i) => <div key={i} className='dentist-office-detail'>{day}: {off['parsed_office_hours'][day]}</div>)
                        }
                      </div>
                    </div>
                }
              </div>
            )
          })
        }
        {/* <Form onSubmit={handleSubmit} formErrors={formErrors}>
          <FieldContainer
            type='text'
            name='name'
            label='Name'
          />
          <FieldContainer
            type='text'
            name='email'
            label='Email'
          />
          <FieldContainer
            type='phone'
            name='phone'
            label='Phone'
          />
          <FieldContainer
            type='textarea'
            rows={4}
            name='message'
            label='How Can We Help?'
          />
          <ReCAPTCHA
            sitekey={process.env.REACT_APP_RECAPTCHA_SITE_KEY}
            onChange={handleRecaptchaSubmit}
          />
          <Button name='submitBtn' disabled={!recaptchaResult}>Send Email</Button>
        </Form> */}
      </div>
    )
  } else if (practice) {
    return (
      <div className='consultation-form'>
        <div className='schedule-consultation'>Contact Us</div>
        <div className='dentist-office-info'>
          <div className='dentist-office-detail'>{practice['address_1']} {practice['address_2'] ? practice['address_2'] : ''}</div>
          <div className='dentist-office-detail'>{practice['city']}, {practice['state']} {practice['zip']}</div>
          {
            practice['phone'] && <div className='dentist-office-detail'>Main: {formatPhoneNumber(practice['phone'])}</div>
          }
          {
            practice['fax'] && <div className='dentist-office-detail'>Fax: {formatPhoneNumber(practice['fax'])}</div>
          }
        </div>
      </div>
    )
  } else {
    return (
      <div className='consultation-form'>
        <div className='schedule-consultation'>Contact Us</div>
        <p>Reach out to us for more information.</p>
      </div>
    )
  }
}

export default RequestConsultationForm

import React from 'react'
import { Link } from 'react-router-dom'
import { Button as ButtonRS } from 'reactstrap'
import { withTracking } from 'helpers'
// import styles from './Button.scss'

const Button = ({ id, value, label, children, href, onClickButtonWithTracking, onClick, gaTrack = true, ...rest }) => {
  const withLink = <Link to={href || ''}>
    <ButtonRS
      id={id}
      value={value}
      onClick={gaTrack ? onClickButtonWithTracking : onClick}
      {...rest}
    >
      {label || children}
    </ButtonRS>
  </Link>

  const button = <ButtonRS
    id={id}
    value={value}
    onClick={gaTrack ? onClickButtonWithTracking : onClick}
    {...rest}
  >
    {label || children}
  </ButtonRS>

  return href ? withLink : button
}

export default withTracking(Button)

import { compose, withHandlers, withStateHandlers } from 'recompose'
import { storage } from 'lib'
import { withRouter } from 'react-router'
import Header from './Header'

export default compose(
  withRouter,
  withStateHandlers({
    isCollapseOpen: false
  }, {
    toggleCollapse: ({ isCollapseOpen }) => () => ({ isCollapseOpen: !isCollapseOpen })
  }),
  withHandlers({
    handleFindTeam: ({ history }) => () => {
      storage.clear()
      history.push('/')
    }
  })
)(Header)

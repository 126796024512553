import { compose } from 'recompose'
import { withEither } from '@bowtie/react-utils'
import DefaultFormGroup from './DefaultFormGroup'
import RadioFormGroup from './RadioFormGroup'
import ImageFormGroup from './ImageFormGroup'

const radioGroupConditionFn = ({ radio }) => radio
const imageGroupConditionFn = ({ type }) => type === 'image'

export default compose(
  withEither(imageGroupConditionFn, ImageFormGroup),
  withEither(radioGroupConditionFn, RadioFormGroup)
)(DefaultFormGroup)

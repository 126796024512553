/* eslint-disable camelcase */

import React from 'react'
import {
  Row,
  Col,
  Subtitle,
  Modal,
  ModalBody,
  Button,
  PracticeLogo,
  TeamLoader,
  Link
} from 'atoms'
import {
  GoogleMap,
  LocateTeamForm
} from 'molecules'
import { ModalHeader } from 'reactstrap'

const PracticeOfficeWindow = (obj) => (
  <div className='team-infowindow'>
    <Link className='strong' to={`/practices/${obj['slug']}`}>
      {obj['name']}
    </Link>
  </div>
)

const LocateTeam = (props) => {
  const { surgeonGroups, allSurgeonGroups, isModalOpen, toggleModal, locateFormData, selectTeam, isTeamSearchLoading } = props
  return (
    <section>
      <Row className='locate-team-section' >
        <Row className='locate-team-row'>
          <Col sm='12' md='6' lg='5' className='locate-team-content'>
            <Subtitle>FIND YOUR ASIRD TEAM</Subtitle>
            <div className='locate-team-description'>
              <strong>Find the implant team in your area.</strong>
              <div>
                The American Society of Implant & Reconstructive Dentistry (ASIRD) is growing quickly. Our highly skilled dentist and oral surgeon teams work together, utilizing state-of-the-art dental implant technology, to create your custom implant solution. To find your ASIRD team, choose a location from the map, or simply submit your zip code below.
              </div>
            </div>
            <LocateTeamForm {...props} />
          </Col>
          <Col sm='12' md='6' lg='7' className=''>
            <GoogleMap
              zoom={3}
              defaultCenter={{ lat: 37.8, lng: -114.6 }}
              markers={allSurgeonGroups}
              infoWindow={PracticeOfficeWindow}
            />
          </Col>
        </Row>
      </Row>

      <Modal isOpen={isModalOpen} toggle={toggleModal} size='lg'>
        <ModalHeader toggle={toggleModal}>
          <Row className='professional-title-section' style={{ marginLeft: '12px' }}>
            <Col sm='12' className='profile-title'>Your ASIRD Team</Col>
            <Col sm='12' className='profile-subtitle'>Location: {`${locateFormData['city'] ? `${locateFormData['city']}, ` : ''}${locateFormData['state'] ? `${locateFormData['state']}` : ''}${locateFormData['zipcode'] ? ` ${locateFormData['zipcode']}` : ''}`}</Col>
          </Row>
        </ModalHeader>
        <ModalBody>
          {
            isTeamSearchLoading
              ? <TeamLoader />
              : (surgeonGroups.length > 0
                ? surgeonGroups.map((sur, i) => (
                  <Row key={i}>
                    <Col sm='12' className={'flex-column center'}>
                      <PracticeLogo src={sur.practice_logo} size='md' />
                      <p>{sur.name}</p>
                    </Col>
                    <Col sm='12' className={'flex-column center'}>
                      {
                        sur['areas_serviced']
                          ? <p>{sur['areas_serviced']}</p>
                          : <p>{sur.city}, {sur.state}</p>
                      }
                    </Col>
                    <Col sm='12' className={'flex-column center'}>
                      <Button className='view-team-btn' onClick={() => selectTeam(sur.slug)}>Select Team</Button>
                    </Col>
                  </Row>
                ))
                : <Row>
                  <Col className='text-danger flex center'>We couldn't find a Team in your location.</Col>
                </Row>
              )
          }

        </ModalBody>
      </Modal>
    </section>
  )
}

export default LocateTeam
